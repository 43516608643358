import { useState } from "react"
import { arrowLeft, arrowRight, reset, saveMapping } from "../../../../constant/icons"
import { wizardStepActionType, wizardStepType } from "../../type"
import { NewMap } from "../addNewMap"

const Footer = (props:any) => {
 const {currentStep,wizardStepAction,onStepUpdate,onColumnValueReset}=props

return (<div className="btn-box maping-btn-box">
  
    <div>{currentStep > wizardStepType.BrowserFile && <button className="btn btn-outline-dark" title="Back" type="submit" onClick={() => { onStepUpdate(wizardStepActionType.back) }}>{arrowLeft.icon} Back</button>}</div>
    <div>
      
     {currentStep===wizardStepType.ColumnsMap&&<button type="submit" title="Reset All" onClick={onColumnValueReset} className="btn-sm btn btn-outline-danger">{reset.icon} Reset All</button>}
        
      {currentStep < wizardStepType.ReviewAndUpload && <button className="btn btn-primary" title="Next" type="submit" onClick={() => onStepUpdate(wizardStepActionType.next)}>Next{arrowRight.icon}</button>}
    </div>
    
  </div>)
}
export {Footer}