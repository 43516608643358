import { DataType } from "ka-table"
import { TableColumn } from "../../components/table/table"
import { CheckboxControl } from "../../components/buttons/checkbox"
import { getModuleName } from "./helper"

export const columns=(actions:any):TableColumn[]=>[
    { 
        key: 'id', 
        title: 'id', 
        dataType: DataType.Number,
        visible:false
    },
    { 
        key: 'moduleCode', 
        title: 'Module Name', 
        dataType: DataType.String,
        valueConvert:getModuleName
    },
    { 
        key: 'add', 
        title: 'Add', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'add'
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'update', 
        title: 'Update', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'update'
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'delete', 
        title: 'Delete', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'delete'
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'view', 
        title: 'View', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'view'
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'downloadError', 
        title: 'Download Error', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'downloadError'
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'repost', 
        title: 'Re Post', 
        dataType: DataType.Boolean,
        component: CheckboxControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:row.checkBoxChecked,
                permissionName:'repost'
            })
        },
        style: {
            textAlign: 'center',
        }
    }
]