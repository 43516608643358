export const JobStatus = {
    PENDING: {
        id: 'PENDING',
        label: 'Pending'
    },
    READY_TO_PROCESS: {
        id: 'READY_TO_PROCESS',
        label: 'Ready to Process'
    },
    IN_PROGRESS: {
        id: 'IN_PROGRESS',
        label: 'In Progress'
    },
    DONE: {
        id: 'DONE',
        label: 'Done'
    },
    DONE_WITH_ERROR: {
        id: 'DONE_WITH_ERROR',
        label: 'Done with Error'
    },
    ERROR: {
        id: 'ERROR',
        label: 'Data Have some Error'
    },
    IN_PROGRESS_RE_POST: {
        id: 'IN_PROGRESS_RE_POST',
        label: 'Re Post in Progress'
    },
} as const

export enum wizardStepType {
    BrowserFile,
    FunctionMap,
    ColumnsMap,
    ReviewAndUpload
}
export enum wizardStepActionType {
    next,
    back
}

export interface columnMap {
    Source: string,
    DestinationLabel: string,
    DestinationCode: string,
    isRequired: Boolean
    requiredInfo?: string
}
export interface sheet {
    name?: string,
    functionCode?: string
    columnMap?: columnMap[]
}
export interface sheetSet {
    name?: string,
    sourceColumnList?: any[]
}
export interface FileUpload {
    file: any,
    totalSheet?: sheetSet[],
    selectedSheet?: sheet[]
}