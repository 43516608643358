import { FormGroup, Input, Label } from "reactstrap";
import { confirmBox } from "../toast";

const CheckboxControl = (props: any) => {
    const { disabled, column, rowData, value, message } = props;
    return( <FormGroup check inline>
        <Input type="checkbox" checked={value}  onChange={async (e) => {
                if (column.callBack) {
                    column.callBack({...rowData,checkBoxChecked:e.target.checked})
                }
            }} />
        <Label check> </Label>
      </FormGroup>)
};

export { CheckboxControl };