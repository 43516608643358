import PageLayout from "../../layout/page"
import { arrowLeft } from "../../../constant/icons"
import { Button } from "reactstrap";
import { WizardBox } from "./wizardBox/layout";
const AddUpdateJob = (props: any) => {
  const { closeAddUpdate, api } = props
  return (
    <PageLayout
      title={<><Button color="link" className="btn-back" onClick={closeAddUpdate}>{arrowLeft.icon} </Button>Function</>}
      View={<WizardBox
        api={api}
        closeAddUpdate={closeAddUpdate}
      />}
    />
  );
}
export { AddUpdateJob }