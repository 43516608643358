import { DetailControl } from "../../../components/buttons/detail";
import { DownloadControl } from "../../../components/buttons/download";
import { RemoveControl } from "../../../components/buttons/remove";

const ActionButton = (props: any) => {
    return <div className="action_btn">
        <DetailControl {...props} />
        <DownloadControl {...props} />
        <RemoveControl {...props} />

    </div>

};

export { ActionButton };