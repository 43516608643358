import * as Yup from "yup";

const userValidationSchema = Yup.object().shape({
  userName : Yup.string().required("UserName is required"),
  name: Yup.string().required("Name is required"),
  roleId: Yup.string().required("Role is required"),
  emailId: Yup.string().email("Invalid email format").required("Email is required"),
  mobileNo: Yup.string().matches(/^\d{10}$/, "Mobile number must be 10 digits").required("Mobile number is required"),
});

export { userValidationSchema };
