import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { arrowLeft, briefcase, downloadIcon, setting } from "../../constant/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownToggle,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect, useState } from "react";
import { ContactPerson } from "./contactPerson";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDate } from "../../helper/utility";
import { get, post } from "../../helper/api";
import { GetAppId, useApp } from "../../components/app";
import { companyValidationSchema } from "./validationSchema";
import { requiredMarks } from "./helper";
import { message } from "../../components/toast";
import reactSelect from "react-select";
import { useLoaderHelper } from "../../helper/loaderHelper";

const AddUpdateCompany = (props: any) => {
  const loader = useLoaderHelper()
  const app = useApp();
  const { api, value, closeAddUpdate } = props;
  const [formValues, setFormValues] = useState(value)
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  const [appName, setAppName] = useState("App")
  useEffect(() => {
    if (app.currentApp?.appCode) {

      if (app.currentApp?.appCode == "xero") {
        setAppName("XERO")
      }
      else if (app.currentApp?.appCode == "QBO") {
        setAppName("QuickBooks")
      }
      else if (app.currentApp?.appCode == "zoho") {
        setAppName("ZOHO")
      }
      else if (app.currentApp?.appCode == "odoo") {
        setAppName("ODOO")
      }
    }
    else {
      setAppName("App")
    }

  }, [app.currentApp?.appCode])
  const toggleTab2 = (tab: string) => {
    if (activesettingTab !== tab) {
      setActivesettingTab(tab);
    }
  };
  const [activeTab, setActiveTab] = useState("tab1");
  const [activesettingTab, setActivesettingTab] = useState("settingtab1");

  return (
    <PageLayout
      title={
        <>
          <Button
            color="link"
            className="btn-back"
            onClick={() => props.closeAddUpdate()}
          >
            {arrowLeft.icon}
          </Button>
          {value?.id ? "Edit" : "Add"} Company

        </>
      }
      View={
        <div className="company_form">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => toggleTab("tab1")}
              >
                {briefcase.icon}Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => toggleTab("tab2")}
              >
                {setting.icon}Setting
              </NavLink>
            </NavItem>
          </Nav>
          <Formik
            initialValues={formValues}
            validationSchema={companyValidationSchema}
            onSubmit={(values, actions) => {
              if (values?.id > 0) {
                api.updateValue(values, loader).then((result: any) => {
                  if (result?.status) {
                    closeAddUpdate();
                  }
                });
              } else {
                api.create(values).then((result: any) => {
                  if (result?.status) {
                    closeAddUpdate();
                  }
                });
              }
            }}
          >
            {(formProps) => (
              <Form className="mx-0">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="tab1">
                    <div className="form-row">
                      <FormGroup className="col-3 form-floating mb-0">
                        <Field
                          className="form-control"
                          name="name"
                          placeholder="Conversation Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                        <Label>Company name <span style={{ color: 'red' }}>*</span></Label>
                      </FormGroup>
                      <FormGroup className="col-3 form-floating mb-0">
                        <Field
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                        <Label>Email <span style={{ color: 'red' }}>*</span></Label>
                      </FormGroup>
                      <FormGroup className="col-3 form-floating sticky_label mb-0">
                        <Field
                          name="company_settings.dateFormat"
                          component={reactSelect}
                          className="column_field_select"
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          menuPosition={'fixed'}

                          onChange={(newValue: any) => {
                            formProps.setFieldValue("company_settings.dateFormat", newValue.value);
                          }}
                          value={{
                            value: formProps.values?.company_settings?.dateFormat,
                            label: formProps.values?.company_settings?.dateFormat
                          }}
                          options={[{
                            value: "dd/MM/yyyy",
                            label: "DD/MM/YYYY"
                          }, {
                            value: "MM/dd/yyyy",
                            label: "MM/DD/YYYY"
                          }]}
                          placeholder={<div>Select column field</div>}
                        />
                        <ErrorMessage
                          name="company_settings.dateFormat"
                          component="div"
                          className="text-danger"
                        />
                        <Label>Preferred Date Format</Label>
                      </FormGroup>
                      <FormGroup className="col-3 form-floating sticky_label mb-0">
                        <Field
                          component={DatePicker}
                          placeholderText={formProps.values?.company_settings?.dateFormat || "DD/MM/YYYY"}
                          dateFormat={formProps.values?.company_settings?.dateFormat || "dd/MM/yyyy"}
                          className="form-control"
                          name="company_settings.date"
                          selected={getDate(formProps.values?.company_settings?.date)}
                          onChange={(date: any, _: any) => {
                            formProps.setFieldValue("company_settings.date", date);
                          }}
                        />
                        <ErrorMessage
                          name="company_settings.date"
                          component="div"
                          className="text-danger"
                        />
                        <Label>conversion Start date <span style={{ color: 'red' }}>*</span></Label>
                      </FormGroup>
                      <FormGroup className="col-12 form-floating mb-0">
                        <Field
                          className="form-control"
                          component="textarea"
                          rows="4"
                          name="remark"
                          placeholder="Remark"
                        />
                        <ErrorMessage
                          name="remark"
                          component="div"
                          className="text-danger"
                        />
                        <Label>Remark</Label>
                      </FormGroup>
                      {app.currentApp?.appCode == "odoo" && <>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.Odoo_hostingUrl"
                            placeholder="Conversation Name"
                          />
                          <ErrorMessage
                            name="company_settings.Odoo_hostingUrl"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Odoo Hosting URL</Label>
                        </FormGroup>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.Odoo_UserName"
                            placeholder="Conversation Name"
                          />
                          <ErrorMessage
                            name="company_settings.Odoo_UserName"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Odoo UserName</Label>
                        </FormGroup>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.Odoo_Password"
                            placeholder="Odoo API Key"
                          />
                          <ErrorMessage
                            name="company_settings.Odoo_Password"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Odoo API Key</Label>
                        </FormGroup>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.Odoo_DataBaseName"
                            placeholder="Conversation Name"
                          />
                          <ErrorMessage
                            name="company_settings.Odoo_DataBaseName"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Odoo DataBaseName</Label>
                        </FormGroup>

                      </>}
                      {app.currentApp?.appCode && ["odoo", "zoho"].includes(app.currentApp?.appCode) && <>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.tally_company_name"
                            placeholder="Company Name in Tally"
                          />
                          <ErrorMessage
                            name="company_settings.tally_company_name"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Company Name in Tally </Label>
                        </FormGroup>
                        <FormGroup className="col-3 form-floating mb-0">
                          <Field
                            className="form-control"
                            name="company_settings.tally_serial_no"
                            placeholder="Tally Serial No"
                          />
                          <ErrorMessage
                            name="company_settings.tally_serial_no"
                            component="div"
                            className="text-danger"
                          />
                          <Label>Tally serial no</Label>
                        </FormGroup>
                        <Dropdown className="template_download" isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle color='secondary'>
                            <span> 
                              {downloadIcon.icon}
                            </span>
                            {app.currentApp?.templateUrl && <a className="m-0" href={"https://gitrepo.hcshub.com/Satish/TALLY-TDL/archive/main.zip"} target="_blank" style={{ color: 'white' }}>Download TDL</a>}
                          </DropdownToggle>

                        </Dropdown>
                      </>}

                      <Col lg="12">
                        <FieldArray name="contact_person">
                          {(actions: any) => (
                            <Card className="contact_person">
                              <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                <h6>Contact person details</h6>
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={() => {
                                    if (!formProps.errors.contact_person) {
                                      actions.insert(
                                        formProps.values.contact_person.length,
                                        ""
                                      );
                                    }
                                    else {
                                      message("warning", "Please, kindly complete the contact person information first.")
                                    }
                                  }}
                                >
                                  {plus.icon} Add person
                                </Button>
                              </div>

                              {formProps.values.contact_person.map(
                                (contactPerson: any, index: number) => {
                                  return (
                                    <ContactPerson
                                      key={`cps${index}`}
                                      formProps={formProps}
                                      id={index}
                                      contactPerson={contactPerson}
                                      actions={actions}
                                    />
                                  );
                                }
                              )}
                            </Card>
                          )}
                        </FieldArray>
                      </Col>
                    </div>
                  </TabPane>
                  <TabPane tabId="tab2">
                    <div className="company_setting">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={
                              activesettingTab === "settingtab1" ? "active" : ""
                            }
                            onClick={() => toggleTab2("settingtab1")}
                          >
                            General
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              activesettingTab === "settingtab2" ? "active" : ""
                            }
                            onClick={() => toggleTab2("settingtab2")}
                          >
                            Transactions
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activesettingTab={activesettingTab}>
                        <TabPane tabId="settingtab1">
                          <h4>Tab 1 Content</h4>
                          {/* Add your content for Tab 1 here */}
                        </TabPane>
                        <TabPane tabId="settingtab2">
                          <h4>Tab 2 Content</h4>
                          {/* Add your content for Tab 2 here */}
                        </TabPane>
                        {/* Add more TabPanes for additional tabs */}
                      </TabContent>
                    </div>
                  </TabPane>
                  <Col
                    lg="12"
                    className="d-flex justify-content-between align-items-center mt-3"
                  >
                    {formProps.values?.id && (
                      <Button
                        disabled={
                          formProps.values?.tenant &&
                          formProps.values?.tenant?.[0]?.id > 0
                        }
                        color="primary"
                        type="button"
                        onClick={() => {
                          if (formProps.values?.tenant) {
                            get("/connectTenant", {
                              companyId: formProps.values?.id,
                            }, loader)
                              .then((response) => {
                                if (response && response?.data.length > 0) {
                                  window.location.href = response?.data[0];
                                }
                              })
                              .catch(() => { });
                          }
                        }}
                      >
                        {formProps.values?.tenant &&
                          formProps.values?.tenant?.[0]?.id > 0
                          ? `Linked to the ${appName} ${formProps.values?.tenant?.[0].tenantName
                          } tenant`
                          : `Connect to ${appName} Tenant`}
                      </Button>
                    )}

                    <Button color="primary" type="submit">
                      {formProps.values?.id > 0 ? "Update" : "Save"}
                    </Button>
                  </Col>
                </TabContent>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  );
};
export { AddUpdateCompany };
