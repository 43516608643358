import { TabContent, TabPane } from "reactstrap";
import { DataView } from "../../components/table/table";
import { columns } from "./columns";

const RolePermissions = (props: any) => {
  return (
    <TabContent>
      <TabPane>
        <DataView
          className="table-striped"
          columns={columns({ api: props.api })}
          rows={props.permissions}
          isLoading={props.api.isLoading}
        />


      </TabPane>
    </TabContent>
  )
}

export { RolePermissions }
{/* <TabContent activeTab="1">
    <TabPane tabId="1">
      <DataView
        className="table-striped"
        columns={columns({ api,openAddEditFrom:AddUpdate.openAddEditFrom })}
        rows={api.data}
      />
    </TabPane>
    <TabPane tabId="2">
      jdhasjk
    </TabPane>
  </TabContent> */}