import { wizardStepType } from "../../type"
const getClassName = (current: wizardStepType, type: wizardStepType) => {
  return current > type ? "active" : current === type ? "active" : ""
}
const WizardStep = (props: any) => {
  const { currentStep } = props
  return (<ul className="wizard-count-list">
    <li className={getClassName(currentStep, wizardStepType.BrowserFile)} >
      <span>1</span>
      <p>Browse File</p>
    </li>
    <li className={getClassName(currentStep, wizardStepType.FunctionMap)}>
      <span>2</span>
      <p>Function Mapping</p>
    </li>
    <li className={getClassName(currentStep, wizardStepType.ColumnsMap)}>
      <span>3</span>
      <p>Columns Mapping</p>
    </li>
    <li className={getClassName(currentStep, wizardStepType.ReviewAndUpload)}>
      <span>4</span>
      <p>Review and Upload</p>
    </li>
  </ul>)
}
export { WizardStep }