import { Link, useNavigate } from "react-router-dom"
import { Button, Col, FormGroup, Input, Label, Spinner } from "reactstrap"
import { post } from "../../helper/api"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { forgotPasswordValidationSchema } from "./validationSchema"
import { useLoaderHelper } from "../../helper/loaderHelper"

export const ForgotPassword = ({ children }: any) => {
  const navigate = useNavigate()
  const loader = useLoaderHelper()
  const loading = useLoaderHelper()
  return <Formik
    initialValues={{
      email: ''
    }}
    validationSchema={forgotPasswordValidationSchema}
    onSubmit={async (values, actions) => {
      const result = await post('/forgotPassword', { email: values.email }, undefined, loader)
      if (result?.status) {
        navigate("/")
        console.log("resultD", result)
      }

    }}
  >
    {(formProps) => (
      <Form className='form-horizontal form-login row'>
        <FormGroup>
          <Label className='form-label' htmlFor="email"> </Label>
          <Field
            className="form-control"
            id="email"
            name="email"
            placeholder='Email'
            type="email"
          />

          <ErrorMessage
            name="email"
            component="div"
            className="text-danger"
          />
        </FormGroup>

        <Col lg="12">
          <Button color='primary' block className="w-100" type="submit">{loading.dataIsLoading ? <Spinner size="sm" /> : 'Forgot password'}</Button>
        </Col>
        <Col lg="12" className='text-center'>
          <Link to="/login" className="pt-3 d-inline-block mx-auto">Back to login</Link>
        </Col>
      </Form>
    )}
  </Formik>
}