import { useState } from "react";
import { detailInfo } from "../../constant/icons";
import { Button, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { message } from "../toast";
import { START_PROCESS } from "../../pages/job/components/message";

const DetailControl = (props: any) => {
    const { column, rowData } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    return (
        <>
            <Button color="link" onClick={() => {
                if (rowData.jobStatus == "PENDING") {
                    message("info", START_PROCESS);
                    return false
                }
                column.callBack({ ...rowData, clickType: "details" })
            }} className="text-info" id="detailsTooltip">{detailInfo.icon}</Button>
            <UncontrolledTooltip isOpen={tooltipOpen} target="detailsTooltip" toggle={toggleTooltip}>
                Details
            </UncontrolledTooltip>
        </>

    )
};

export { DetailControl };
