import axios, { AxiosResponse } from "axios";
import { getToken } from "../components/auth";
import { message } from "../components/toast";
import { GetAppId } from "../components/app";
import { useLoaderHelper } from "./loaderHelper";

export const API_URL =
  window.location.hostname === "localhost"
    ?"http://localhost:3300" 
    : window.location.origin + "/uat-accountsync-api"//accountsync-api"//xerotest-api";
const axiosInstance = axios.create({
  
  baseURL: API_URL,
});
axiosInstance.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  config.headers.set("appId", GetAppId());
  return config;
});
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      let token:any=window.sessionStorage.getItem("token")
      window.sessionStorage.clear();
      if(token){
        token=JSON.parse(token)
        if(token.loginFrom){
          window.location.href =`/${token.loginFrom}/login`; 
          return Promise.reject(error); 
        }
        else if(token.loginType){
          window.location.href =`/${token.loginType}/login`;  
          return Promise.reject(error);
        }
        else{
          window.location.href =`/login`;  
          return Promise.reject(error);
        }
      }
      else{
        window.location.href = '/login';
        return Promise.reject(error);
      }
      
      // if (token) {
      //   try{
      //     token=JSON.parse(token)
      //     window.sessionStorage.clear();
      //     if(token){
      //       window.sessionStorage.setItem("typeLogin",token.loginFrom)
      //     }
          
      //   }catch{

      //   }
        
        
      //   setTimeout(() => window.location.reload(), 3000);
      // }
    }
    return Promise.reject(error);
  }
);

export const fetcher = (url: string, body: object = {}, loading: any) =>
  get(url, body, loading).then((res) => res.data);
export const get = async <T>(url: string, data: any = {}, loading: any=undefined) => {
  if(loading){
    loading.setDataIsLoading(true);
  }
  
  let response: any = {};
  try {
    response = await axiosInstance.get(url, {
      params: data,
    });
  } catch (error: any) {
    response = error.response;
  } finally {
    if (response && response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    if(loading){
      loading.setDataIsLoading(false);
    }
    

    return response?.data;
  }
};

export const post = async <T>(url: string,data: T,isResponse: boolean = false,loading: any=undefined) => {
  if(loading){
    loading.setDataIsLoading(true);
  }
  
  let response: any = {};
  try {
    response = await axiosInstance.post(
      url,
      data,
      isResponse ? { responseType: "blob" } : undefined
    );
  } catch (error: any) {
    response = error.response;
  } finally {
    if (response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    if(loading){
      loading.setDataIsLoading(false);
    }
    
    return isResponse ? response : response.data;
  }
};
export const update = async <T>(url: string, data: T, loading: any) => {
  if(loading){
    loading.setDataIsLoading(true);
  }
  
  let response: any = {};
  try {
    response = await axiosInstance.put(url, data);
  } catch (error: any) {
    response = error.response;
  } finally {
    if (response.data.message && response.data.message.length > 0) {
      message(
        response.data.status ? "success" : "error",
        response.data.message
      );
    }
    if(loading){
      loading.setDataIsLoading(false);
    }
    
    return response.data;
  }
};

export const statusUpdate = async <T>(url: string, data: T, loading: any) => {
  let response: any = {};
  loading.setDataIsLoading(true)
  try {
    response = await axiosInstance.patch(url, data);
  } catch (error: any) {
    response = error.response;
  } finally {
    if (response?.data?.message && response?.data?.message?.length > 0) {
      message(
        response?.data?.status ? "success" : "error",
        response?.data?.message
      );
    }
    loading.setDataIsLoading(false)
    return response?.data;
  }
};

export const del = async <T>(url: string, loading: any) => {
  let response = await axiosInstance.delete(url);
  return response.data;
};

