import { Button, Col, FormGroup, Label, Row } from "reactstrap"
import PageLayout from "../layout/page"
import { arrowLeft, arrowRight, deleteIcon, file, fileupload } from "../../constant/icons"
import ReactSelect from "react-select"
import ReactDatePicker from "react-datepicker"
import { DataView } from "../../components/table/table"
import { columns } from "./columns"
import { useApi } from "../../helper/apiHook"

const BalanceSheetView = () => {
  const api=useApi("balanceSheet")
    return <PageLayout
    title={
      <>
      <Button color="link" className="btn-back">{arrowLeft.icon} </Button>Balance Sheet
      </>}
      View={<>
      
      <div className="balancesheet-view">
        <div className="balancesheet-view-head p-3 pb-0 mb-3">          
            <FormGroup className="form-floating sticky_label">
              <ReactDatePicker
                onChange={()=>{}}
                placeholderText="DD/MM/YYYY"                
                className="form-control"  
              >                
              </ReactDatePicker>             
              <Label>conversion Start date <span style={{ color:'red'}}>*</span></Label>
            </FormGroup>                  
            <FormGroup className="form-floating sticky_label">
              <ReactDatePicker
                onChange={()=>{}}
                placeholderText="DD/MM/YYYY"                
                className="form-control"  
                >
              </ReactDatePicker>
              <Label>conversion Start date <span style={{ color:'red'}}>*</span></Label>
            </FormGroup>                          
        </div>        
          <DataView
                columns={columns({})}
                rows={undefined}
              />
          <div className="btn-box">
            <button className="btn btn-primary" title="Next" type="submit">Next{arrowRight.icon}</button>          
          </div>          
      </div>
        
        </>}
    />
    
    
  }
  
  
  export { BalanceSheetView }