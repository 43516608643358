import { DataType } from 'ka-table/enums';
import { TableColumn } from '../../components/table/table';
import { StatusControl } from '../../components/buttons/status';
import { EditControl } from '../../components/buttons/edit';
import { SwitchButton } from '../../components/buttons/switchButton';
import { useApiType } from '../../helper/apiHook';
import { connectToAPP } from './helper';
import { InfoControl } from '../../components/buttons/info';
import { ActionButton } from './actionButtons';


export const columns = (api: useApiType, actions: any, loader: any): TableColumn[] => [
    {
        key: 'id',
        title: 'id',
        dataType: DataType.Number,
        visible: false
    },
    {
        key: 'name',
        title: 'Company Name',
        dataType: DataType.String,

        callBack: (row) => {
            actions.conversation(row)
        }

    },
    {
        key: 'email',
        title: 'Email',
        dataType: DataType.String,
        callBack: (row) => {
            actions.conversation(row)
        }
    },
    {
        key: 'isActivate',
        title: 'Status',
        hideHeaderFilter: true,
        dataType: DataType.Boolean,
        component: StatusControl,
        callBack: (row) => {
            api.status({
                id: row.id,
                isActivate: !row.isActivate
            })
        },
        style: {
            textAlign: 'center'
        }
    },
    {
        key: 'isConnected',
        title: 'connected with app',
        dataType: DataType.Boolean,
        hideHeaderFilter: true,
        component: SwitchButton,
        callBack: (row) => {
            if (row.tenant?.[0]?.isConnected) {
                api.serverRequest({
                    url: "/disConnectTenant", requestType: 'POST', body: {
                        tenantId: row.tenant[0].id
                    }
                })
            }
            else {
                connectToAPP("/connectTenant", { companyId: row.id }, loader)
            }
        },
        style: {
            textAlign: 'center',
        }
    },
    {
        key: 'edit',
        title: '',
        hideHeaderFilter: true,
        component: ActionButton,
        callBack: (row) => {
            if (row.info) {
                actions.onJobView(row.row.id, row.row)
            }
            else {
                actions.openAddEditFrom(row)
            }

        },
        style: {
            textAlign: 'center',
        }
    },
]