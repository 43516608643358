import { EditControl } from "../../components/buttons/edit";
import { InfoControl } from "../../components/buttons/info";

const ActionButton = (props: any) => {
    return <div className="action_btn">
        <EditControl {...props}/>
        <InfoControl {...props}/>
    </div>

};

export { ActionButton };