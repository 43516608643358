import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Page404 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let applicationMode: any = location.pathname.split('/')[1];
    if (!['odoo', "QBO", "xero", "zoho", "qbo"].includes(applicationMode)) {
        applicationMode = undefined;
    }
    useEffect(() => {
        if (applicationMode) {
            navigate("/login");
        }
    }, [applicationMode, navigate]);

    return (
        <>
            <h1>404 Welcome</h1>
        </>
    );
};

export default Page404;
