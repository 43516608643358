import PageLayout from "../layout/page"
import { arrowLeft, checkIcon, deletebinIcon, rightArrow } from "../../constant/icons"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useApp } from "../../components/app";
import { useNavigate } from "react-router-dom";
import { DataView } from "../../components/table/table";
import { useEffect, useState } from "react";
import { DeleteList } from "./delete";
import deletefile_img from "../../assets/images/delete_file.png";

const DeleteListInfo = () => {
  const app = useApp()
  const [appName,setAppName]=useState("App")
  let navigate = useNavigate()
  if (!app.conversation) {
    navigate('/')
  }
  useEffect(()=>{
    if(app.currentApp?.appCode){

        if(app.currentApp?.appCode=="xero"){
          setAppName("XERO")    
        }
        else if(app.currentApp?.appCode=="QBO"){
          setAppName("QuickBooks")    
        }
        else if(app.currentApp?.appCode=="zoho"){
          setAppName("ZOHO")    
        }
        else if(app.currentApp?.appCode=="odoo"){
          setAppName("ODOO")    
        }
    }
    else{
      setAppName("App")
    }

  },[app.currentApp?.appCode])

  const [deleteInfo, setDeleteInfo] = useState(false)
  const [isDeleteInfo, setIsDeleteInfo] = useState(false)
  const [isDeleteByReference, setIsDeleteByReference] = useState<boolean>(false)
  const toggle = () => {
    setIsDeleteInfo(!isDeleteInfo)
  }
  return (<PageLayout
    title={<><Button color="link" onClick={() => navigate('/')} className="btn-back">{arrowLeft.icon} </Button>{
      isDeleteInfo ? isDeleteByReference ? "Delete By Reference" : "Delete By Search" : "Delete"
    }</>}

    View={deleteInfo ? <DeleteList
      isDeleteByReference={isDeleteByReference}
    /> : <>
      <Modal className="job-modal job_log_modal delete_option_modal" isOpen={isDeleteInfo} size="lg" centered scrollable={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Choose Your Preferred Way to Delete Your Data:
        </ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">
            <FormGroup check onClick={() => {
              setIsDeleteByReference(false)
              setDeleteInfo(true)
            }} className="mb-3">
              <Label check>
                <Input type="radio" name="radio1" />
                <div className="radio_content">
                  <div>
                    <h3>Delete by Searching</h3>
                    <p>swiftly removes unwanted files across devices, ensuring a streamlined and secure digital decluttering experience.</p>
                  </div>
                  <span className="delete_bin">{deletebinIcon.icon}</span>
                  <span className="delete_check_icon">{checkIcon.icon}</span>
                </div>
              </Label>
            </FormGroup>
            <FormGroup check onClick={() => {
              setIsDeleteByReference(true)
              setDeleteInfo(true)
            }}>
              <Label check>
                <Input type="radio" name="radio1" />
                <div className="radio_content">
                  <div>
                    <h3>Delete by Reference</h3>
                    <p> streamlines file management, letting users effortlessly delete specific files through referencing, simplifying digital organization.</p>
                  </div>
                  <span className="delete_bin">{deletebinIcon.icon}</span>
                  <span className="delete_check_icon">{checkIcon.icon}</span>
                </div>
              </Label>
            </FormGroup>
          </FormGroup>

        </ModalBody>
        <ModalFooter>

          <Button onClick={() => setDeleteInfo(true)}>Next<span>{rightArrow.icon}</span></Button>
        </ModalFooter>
      </Modal>
      <div className="modify_top_section delete_top_section">
        <div className="modify_text">
          <h3>Delete Data</h3>
          <p>Unleash the true potential of your digital assets with MMC : Excel to {appName} tool, the all-in-one solution for effortless file customization. Whether you're tweaking documents, enhancing images, or fine-tuning data, our user-friendly platform empowers you to modify files with unparalleled ease.</p>
        </div>
        <div className="modify_img">
          <img src={deletefile_img} alt="modify" />
        </div>
      </div>
      <div className="modify_bottom_section delete_bottom_section">
        <h2>How to Delete Data with MMC Transactions?</h2>
        <ul className="process_card">
          <li>
            <span>1</span>
            <h3>Search the Data in {appName}</h3>
            <p>Unleash the true potential of your digital assets with MMC : Excel to {appName} tool, the all-in-one solution for effortless file customization.</p>
          </li>
          <li>
            <span>2</span>
            <h3>Select the Data</h3>
            <p>Unleash the true potential of your digital assets with MMC : Excel to {appName} tool, the all-in-one solution for effortless file customization.</p>
          </li>
          <li>
            <span>3</span>
            <h3>Delete with Confidence</h3>
            <p>Unleash the true potential of your digital assets with MMC : Excel to {appName} tool, the all-in-one solution for effortless file customization.</p>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-end modify_btn">
        <Button onClick={() => setIsDeleteInfo(true)} >Let's Delete<span>{rightArrow.icon}</span></Button>
      </div>
    </>}
  />
  );
}
export { DeleteListInfo }