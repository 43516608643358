export const Module={
    COMPANY_MASTER:{
        id:'COMPANY_MASTER',
        name:'COMPANY MASTER'
    },
    JOB_MASTER:{
        id:'JOB_MASTER',
        name:'JOB'
    },
    USER_MASTER:{
        id:'USER_MASTER',
        name:'USER Master'
    },
    BALANCE_SHEET:{
        id:'BALANCE_SHEET',
        name:'Balance sheet'
    },
    ROLE_MASTER:{
        id:'ROLE_MASTER',
        name:'Role Master'
    },
    EMAIL_TEMPLATE:{
        id:'EMAIL_TEMPLATE',
        name:'Email Template'
    },
} as const

export const getModuleName = (module: string): string => {
  switch (module) {
    case Module.BALANCE_SHEET.id:{
        return Module.BALANCE_SHEET.name
    }
    case Module.COMPANY_MASTER.id:{
        return Module.COMPANY_MASTER.name
    }
    case Module.EMAIL_TEMPLATE.id:{
        return Module.EMAIL_TEMPLATE.name
    }
    case Module.JOB_MASTER.id:{
        return Module.JOB_MASTER.name
    }
    case Module.ROLE_MASTER.id:{
        return Module.ROLE_MASTER.name
    }
    case Module.USER_MASTER.id:{
        return Module.USER_MASTER.name
    }
    default: {
      return module;
    }
  }
};

