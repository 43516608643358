import * as Yup from "yup";

const emailValidationSchema = Yup.object().shape({
  subject: Yup.string()
    .required("Subject is required")
    .max(100, "Subject must be at most 100 characters"),

  emailBody: Yup.string().required("Email body is required"),
});

export { emailValidationSchema };
