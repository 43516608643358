import { ErrorMessage, Field, getIn } from "formik";
import { Button, CardBody, FormGroup, Label } from "reactstrap";
import { deleteIcon } from "../../constant/icons";

const ContactPerson = (props: any) => {
  console.log("formProps", props?.formProps)
  const canErrorView=(name:string,id:number)=>{
  try{
    if(props?.formProps?.touched?.contact_person&&props?.formProps?.errors?.contact_person&&props?.formProps?.errors?.contact_person.length>0){
      if(props?.formProps?.errors?.contact_person[props.id][name])
      return <div className="text-danger">{props?.formProps?.errors?.contact_person[props.id][name]}</div>
    }  
  }
  catch{

  }
  return <></>
  }
  return (
    <CardBody key={`ContactPersonCard${props.id}`} className="form-row pt-0">
      <FormGroup key={`contact_personName${props.id}`} className="col form-floating mb-0">
        <Field
          className="form-control"
          name={`contact_person.${props.id}.name`}
          placeholder="Contact person"
        />
        {canErrorView("name",props.id)}

        {/* <ErrorMessage
          name={`contact_person.${props.id}.name`}
          component="div"
          className="text-danger"
        /> */}
        <Label>Name</Label>
      </FormGroup>
      <FormGroup key={`contact_personEmail${props.id}`} className="col form-floating mb-0">
        <Field
          className="form-control"
          type="email"
          name={`contact_person.${props.id}.email`}
          placeholder="email"
        />
        {canErrorView("email",props.id)}
        {/* {props?.formProps?.touched?.contact_person && props?.formProps?.errors?.contact_person && props?.formProps?.errors?.contact_person[props.id].email &&
          <div className="text-danger">{props?.formProps?.errors?.contact_person[props.id].email}</div>} */}
        {/* <ErrorMessage
          name={`contact_person.${props.id}.email`}
          component="div"
          className="text-danger"
        /> */}
        <Label>Email</Label>
      </FormGroup>
      <FormGroup key={`contact_personMobile${props.id}`} className="col form-floating mb-0">
        <Field
          className="form-control"
          name={`contact_person.${props.id}.mobileNo`}
          placeholder="Mobile no."
        />
        {canErrorView("mobileNo",props.id)}
        {/* {props?.formProps?.touched?.contact_person && props?.formProps?.errors?.contact_person && props?.formProps?.errors?.contact_person[props.id].mobileNo &&
          <div className="text-danger">{props?.formProps?.errors?.contact_person[props.id].mobileNo}</div>} */}
        {/* <ErrorMessage
          name={`contact_person.${props.id}.mobileNo`}
          component="div"
          className="text-danger"
        /> */}
        <Label>Mobile No.</Label>
      </FormGroup>
      <FormGroup key={`contact_personDesignation${props.id}`} className="col form-floating mb-0">
        <Field
          className="form-control"
          name={`contact_person.${props.id}.designation`}
          placeholder="Designation"
        />
        {canErrorView("designation",props.id)}
        {/* {props?.formProps?.touched?.contact_person && props?.formProps?.errors?.contact_person && props?.formProps?.errors?.contact_person[props.id].designation &&
          <div className="text-danger">{props?.formProps?.errors?.contact_person[props.id].designation}</div>} */}
        {/* <ErrorMessage
          name={`contact_person.${props.id}.designation`}
          component="div"
          className="text-danger"
        /> */}
        <Label>Designation</Label>
      </FormGroup>
      <Button
        color="link"
        onClick={() => {
          props.actions.remove(props.id);
        }}
        className="text-danger ps-0"
      >
        {deleteIcon.icon}
      </Button>
    </CardBody>
  );
};
export { ContactPerson };
