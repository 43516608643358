import {
  Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap"
import plans_bg_color from '../../src/assets/images/hero_bg.png'
import plans_bg_noice from '../../src/assets/images/hero_Noise.png'
import about01_img from '../assets/images/about-us01.png'
import about02_img from '../assets/images/about02.png'
import about03_img from '../assets/images/about03.png'
import about04_img from '../assets/images/about04.png'
import { arrowlineIcon, checkarrowIcon } from "../constant/icons"
import logo_01 from '../assets/images/xero_logo-ex.png'
import logo_02 from '../assets/images/intuit_quickbooks_logo-ex.png'
import logo_03 from '../assets/images/freshbooks-logo-1.png'
import logo_04 from '../assets/images/myob_logo.png'
import logo_05 from '../assets/images/reckon-logo.png'
import logo_06 from '../assets/images/netsuite-logo.png'
import logo_07 from '../assets/images/odoo-logo.png'
import how_towork01 from '../assets/images/loading_file.png'
import how_towork02 from '../assets/images/SelectService.png'
import how_towork03 from '../assets/images/MakePayment.png'
import how_towork04 from '../assets/images/Leavefile.png'
import how_towork05 from '../assets/images/SubscriptionTransfer.png'
import { useState } from "react"
import { Header } from "./layout/userheader"
import { useLocation, useNavigate } from "react-router-dom"

const Userguide = () => {
  const currentLocation = useLocation()
  let loginType: string | undefined | null = currentLocation.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo", "XERO"].includes(loginType)) {
    loginType = undefined
  }
  console.log("loginType==", loginType)
  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const [open, setOpen] = useState<any>('');
  const navigate = useNavigate()
  const toggle = (id: any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Header />
      <section className="plans_hero workflow_head">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={7} className="plans_main_text">
              <h1><span>Streamline </span>Your Workflow</h1>
              <p>with MMC Converter Integration Guide</p>
            </Col>
          </Row>
        </Container>
        <img className='plans_bg_noice' alt="background" src={plans_bg_noice} />
        <img className='plans_bg_color' alt="background" src={plans_bg_color} />
      </section>
      <section className="workflow_content">
        <Container className="h-100">
          <Row>
            <Col lg={12} className="workflow_col">
              <div className="workflow_title">
                <h2>1.</h2>
                <div>
                <h3>Introduction</h3>
                <p>Streamline Your Workflow with MMC Excel Converter Integration</p>
                </div>
              </div>
              <div className="workflow_description">
                <p>
                  Welcome to the integration guide for MMC Excel Converter! This tool enables seamless integration with {loginType}, allowing you to efficiently manage your data conversions and streamline your workflow. Follow the steps below to get started.
                </p>
              </div>
            </Col>
            <Col lg={12} className="workflow_col">
            <div className="workflow_title">
                <h2>2.</h2>
                <div>
                <h3>Integration Details</h3>
                <p>Connecting MMC Excel Converter with {loginType}</p>
                </div>
              </div>
              <div className="workflow_description">
                <p>
                This section outlines the step-by-step process to integrate MMC Excel Converter with {loginType}. Follow these instructions to establish a connection between the two platforms and initiate data conversions effortlessly.
                </p>
                <ul>
                  <li>
                    <h2>2.1</h2>
                    <div>
                      <h4>Login to MMC Excel Converter</h4>
                      <p>Log in to your MMC Excel Converter account using your credentials.</p>
                    </div>
                  </li>
                  <li>
                    <h2>2.2</h2>
                    <div>
                      <h4>Add New Conversion</h4>
                      <p>Add a new conversion in MMC Excel Converter by providing mandatory information.</p>
                    </div>
                  </li>
                  <li>
                    <h2>2.3</h2>
                    <div>
                      <h4>Connect to {loginType}</h4>
                      <p> Click on the "Connect" button to link MMC Excel Converter with {loginType}. Follow the prompts to authenticate your {loginType} account.</p>
                    </div>
                  </li>
                  <li>
                    <h2>2.4</h2>
                    <div>
                      <h4>Select Organization</h4>
                      <p>Choose your organization from the dropdown list on the {loginType} website.</p>
                    </div>
                  </li>
                  <li>
                    <h2>2.5</h2>
                    <div>
                      <h4>Redirect to MMC Excel Converter</h4>
                      <p>Once connected, you will be redirected back to MMC Excel Converter to proceed with your data conversions.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={12} className="workflow_col">
            <div className="workflow_title">
                <h2>3.</h2>
                <div>
                <h3>Getting Started</h3>
                <p>Initiating Data Conversions</p>
                </div>
              </div>
              <div className="workflow_description">
                <p>
                This section guides you through the process of initiating data conversions using MMC Excel Converter. Follow these steps to seamlessly convert your CSV files and integrate them into {loginType}.
                </p>
                <ul>
                  <li>
                    <h2>3.1</h2>
                    <div>
                      <h4>Accessing the Conversation Page</h4>
                      <p>Double-click on the "Conversation" tab to navigate to the job page within MMC Excel Converter.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.2</h2>
                    <div>
                      <h4>Adding New Conversion</h4>
                      <p> Click on the "Add New" button on the job page to begin a new conversion process.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.3</h2>
                    <div>
                      <h4>Uploading CSV Files</h4>
                      <p>Browse and select the CSV files from your system that you wish to convert.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.4</h2>
                    <div>
                      <h4>Mapping Functions</h4>
                      <p>Choose the appropriate accounting functions for the uploaded CSV files.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.5</h2>
                    <div>
                      <h4>Mapping Columns</h4>
                      <p>Map the source columns from your CSV files to the destination columns in {loginType}.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.6</h2>
                    <div>
                      <h4>Initiating Conversion</h4>
                      <p>Provide a job name (optional) and submit the conversion request. Your files will be processed in the backend.</p>
                    </div>
                  </li>
                  <li>
                    <h2>3.7</h2>
                    <div>
                      <h4>Review and Upload</h4>
                      <p>After submission, review the job details and upload the files to {loginType} for processing.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={12} className="workflow_col">
              <div className="workflow_title">
                <h2>4.</h2>
                <div>
                <h3>Conclusion</h3>
                <p>Successful Integration</p>
                </div>
              </div>
              <div className="workflow_description">
                <p>
                Congratulations! You have successfully integrated MMC Excel Converter with {loginType} and initiated your first data conversion. For any further assistance or inquiries, please refer to our support resources.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="plans_about plans_contact_us about_contact_us">
        <Container className="h-100">
          <Row className="h-100 d-flex align-items-center">
            <Col lg={9} className="plans_about_text">
              <h1>Accelerate Your Transformation</h1>
              <p>Transform your workflow with lightning-fast, precise file conversions today!</p>
            </Col>
            <Col lg={3} className="plans_about_img d-flex justify-content-end">
              <Button className="btn" onClick={() => navigate("/login")}>Convert Now</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Userguide