import { Field, Form, Formik } from "formik";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { post, update } from "../../../helper/api";
import { columnMap } from "../type";
import { useState } from "react";
import { useLoaderHelper } from "../../../helper/loaderHelper";

export const UpdateMapChanges = async (
  mapId: number,
  mapName: string,
  functionId: number,
  columnMap: any[]
) => {
  let route = "/jobMap";
  let payload: any = {
    name: mapName,
    functionId: functionId,
    mapPayload: columnMap.map((item: columnMap) => {
      return {
        DestinationCode: item.DestinationCode,
        Source: item.Source,
      };
    }),
  };
  if (mapId > 0) {
    payload = { ...payload, groupMapId: mapId };
  }
  
console.log("mapId",mapId)
  return mapId > 0
    ? await update(route, payload,undefined)
    : await post(route, payload, undefined, undefined);
};

export const NewMap = (props: any) => {
  const { isOpen, currentFunction, mapToggle, app } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={mapToggle}
      className="job-modal"
      size="md"
      centered
      scrollable={true}
    >
      <ModalHeader toggle={mapToggle}>
        Map Name for {currentFunction.label} Function
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            name: "",
          }}
          onSubmit={async (value) => {
            let result = await UpdateMapChanges(
              0,
              value.name,
              currentFunction.id,
              currentFunction.columnMap
            );
            if (result && result.status) {
              await app.refreshAppInfo();
              mapToggle();
            }
          }}
        >
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit}>
              <FormGroup className="col-12 form-floating">
                <Field
                  value={formProps.values.name}
                  className="form-control"
                  name="name"
                  placeholder="Enter New Map Name"
                />
                <Label>New Map Name</Label>
              </FormGroup>
              <Button color="primary" type="submit">
                Save Map
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
