import { useEffect, useState } from "react"
import { FileUpload, wizardStepType } from "../../type"
import { BrowserFile } from "./steps/browserFile"
import { FunctionMap } from "./steps/functionMap"
import { useApp } from "../../../../components/app"
import { ColumnsMap } from "./steps/columnsMap"
import { ReviewAndUpload } from "./steps/reviewAndUpload"
import Swal from "sweetalert2"

const Container = (props: any) => {
    const { currentStep, resetId, fileUpload, onFileUpdate, refresh, FileUploadRefresh, onFileUpdateRefresh, api, closeAddUpdate, isUpdate } = props
    const app = useApp()
    const [selectedMap, setSelectedMap] = useState(null)

    return (
        <div className="wizard-container">
            {currentStep === wizardStepType.BrowserFile && <BrowserFile
                fileUpload={fileUpload}
                onFileUpdate={onFileUpdate}
                onFileUpdateRefresh={onFileUpdateRefresh}
            />}
            {currentStep === wizardStepType.FunctionMap && <FunctionMap
                fileUpload={fileUpload}
                onFileUpdate={onFileUpdate}
                refresh={refresh}
                app={app}
            />}
            {currentStep === wizardStepType.ColumnsMap && <ColumnsMap
                fileUpload={fileUpload}
                onFileUpdate={onFileUpdate}
                refresh={refresh}
                resetId={resetId}
                app={app}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
            />}
            {currentStep === wizardStepType.ReviewAndUpload && <ReviewAndUpload
                fileUpload={fileUpload}
                app={app}
                api={api}
                isUpdate={isUpdate}
                closeAddUpdate={closeAddUpdate}
            />}
        </div>
    )
}
export { Container }