import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import plans_bg_color from "../../src/assets/images/hero_bg.png";
import plans_bg_noice from "../../src/assets/images/hero_Noise.png";
import about01_img from "../assets/images/about-us01.png";
import about02_img from "../assets/images/about02.png";
import about03_img from "../assets/images/about03.png";
import about04_img from "../assets/images/about04.png";
import {
  arrowlineIcon,
  call,
  checkarrowIcon,
  clock,
  email,
  location,
} from "../constant/icons";
import logo_01 from "../assets/images/xero_logo-ex.png";
import logo_02 from "../assets/images/intuit_quickbooks_logo-ex.png";
import logo_03 from "../assets/images/freshbooks-logo-1.png";
import logo_04 from "../assets/images/myob_logo.png";
import logo_05 from "../assets/images/reckon-logo.png";
import logo_06 from "../assets/images/netsuite-logo.png";
import logo_07 from "../assets/images/odoo-logo.png";
import how_towork01 from "../assets/images/loading_file.png";
import how_towork02 from "../assets/images/SelectService.png";
import how_towork03 from "../assets/images/MakePayment.png";
import how_towork04 from "../assets/images/Leavefile.png";
import how_towork05 from "../assets/images/SubscriptionTransfer.png";
import { useState } from "react";
import { Header } from "./layout/userheader";
import { useLocation, useNavigate } from "react-router-dom";
// import { Formik } from "formik"
import { Field, Form, Formik } from "formik";
import ReactSelect from "react-select";
import { useApi } from "../helper/apiHook";

const Contactus = () => {
  const currentLocation = useLocation()
  let loginType: string | undefined | null = currentLocation.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo", "XERO"].includes(loginType)) {
    loginType = undefined
  }
  console.log("loginType==", loginType)
  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const api = useApi("sendMail", false);
  const [open, setOpen] = useState<any>("");
  const navigate = useNavigate();
  const toggle = (id: any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  const appCodeList: any = [
    {
      value: "USA",
      label: "USA",
    },
    {
      value: "AUSTRALIA",
      label: "AUSTRALIA",
    },
    {
      value: "UK",
      label: "UK",
    },
    {
      value: "Dubai",
      label: "Dubai",
    },
    {
      value: "India",
      label: "India",
    },
  ];
  return (
    <>
      <Header />
      <section className="plans_hero contact_hero">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={7} className="plans_main_text">
              <h1>
                Contact<span> Us</span>
              </h1>
            </Col>
          </Row>
        </Container>
        <img className="plans_bg_noice" alt="background" src={plans_bg_noice} />
        <img className="plans_bg_color" alt="background" src={plans_bg_color} />
      </section>
      <section className="contact_us">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={7}>
              <div className="contact_about">
                <h4></h4>
                <p>
                  Get immediate assistance for any query you may have regarding
                  MMC.We are available 24x7 to resolve your queries.
                </p>
                <ul className="contact_links">
                  <li>
                    <a
                      href="mailto:info@mmcconvert.com"
                      title="info@mmcconvert.com"
                    >
                      <span>{email.icon}</span> info@mmcconvert.com
                    </a>
                  </li>
                  <li>
                    <a href="tell:info@mmcconvert.com" title="+919926477000">
                      <span>{call.icon}</span> +919926477000
                    </a>
                  </li>
                </ul>
                <div className="contact_office">
                  <h3>Our Offices</h3>
                  <ul className="contact_office_box">
                    <li>
                      {location.icon}
                      <h6>USA</h6>
                      <p>1250 N Lasalle Street Chicago. 60610, USA</p>
                    </li>
                    <li>
                      {location.icon}
                      <h6>AUSTRALIA</h6>
                      <p>
                        Level 1, 1034 Dandenong Rd Carnegie, VIC 3163, Australia
                      </p>
                    </li>
                    <li>
                      {location.icon}
                      <h6>UK</h6>
                      <p>Babel Studios 82 Southwark, Bridge Road, London</p>
                    </li>
                    <li>
                      {location.icon}
                      <h6>Dubai</h6>
                      <p>PO Box 56754 Dubai, UAE</p>
                    </li>
                    <li>
                      {location.icon}
                      <h6>India</h6>
                      <p>11/5, SOUTH TUKOGANI, Indore, India</p>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={5} className="contact_form">
              <div className="contact_form_box">
                <h1>Get in Touch</h1>
                <p>You can reach us anytime.</p>
                <Formik
                  initialValues={{
                    phonenumber: "",
                    name: "",
                    email: "",
                    country: "",
                    query: "",
                  }}
                  onSubmit={(values: any, { resetForm }: any) => {
                    api.create(values);
                    resetForm()
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="name">
                              Name <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="name"
                              name="name"
                              placeholder="Your name"
                              type="text"
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">
                              Email <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="email"
                              name="email"
                              placeholder="Your email"
                              type="email"
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="phonenumber">
                              Phone number{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="phonenumber"
                              name="phonenumber"
                              placeholder="Phone number"
                              type="number"
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="country">
                              Country <span className="text-danger">*</span>
                            </Label>
                            <ReactSelect
                              name="country"
                              onChange={(newValue: any) => {
                                setFieldValue("country", newValue.value);
                              }}
                              options={appCodeList}
                              value={appCodeList?.find(
                                (x: any) => x.value === appCodeList.value
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="country">
                              Query <span className="text-danger">*</span>
                            </Label>
                            <Field
                              className="form-control"
                              component="textarea"
                              rows="4"
                              name="query"
                              placeholder="Any query?"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <Button
                            color="primary"
                            block
                            className="w-100"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="pricing_cards Comprehensive_section">
          <Container className="h-100">
            <Row>
              <Col lg={12}>
                <h3 className="history_title">Comprehensive <span>Conversion</span> Capabilities</h3>
                <p>With MMC Convert, businesses gain access to a comprehensive suite of conversion capabilities. Our services cover the import of various functions within Xero, including but not limited to:</p>
              </Col>
              <Col lg={12}>
                <ul>
                  <li>
                    <span>Chart of Accounts</span>
                  </li>
                  <li>
                    <span>Customer Master</span>
                  </li>
                  <li>
                    <span>Vendor Master</span>
                  </li>
                  <li>
                    <span>Tracking Classes</span>
                  </li>
                  <li>
                    <span>Conversion Balance</span>
                  </li>
                  <li>
                    <span>Tax Master</span>
                  </li>
                  <li>
                    <span>Item Master</span>
                  </li>
                  <li>
                    <span>Invoices</span>
                  </li>
                  <li>
                    <span>Bills</span>
                  </li>
                  <li>
                    <span>Credit Notes</span>
                  </li>
                  <li>
                    <span>Invoice Payments</span>
                  </li>
                  <li>
                    <span>Bill Payments</span>
                  </li>
                  <li>
                    <span>Credit Note Allocation</span>
                  </li>
                  <li>
                    <span>Overpayments</span>
                  </li>
                  <li>
                    <span>Purchase Orders</span>
                  </li>
                  <li>
                    <span>Sales Estimates</span>
                  </li>
                  <li>
                    <span>Reconciled Bank Transactions</span>
                  </li>
                  <li>
                    <span>Manual Journal Entries</span>
                  </li>
                  <li>
                    <span>Trial Balance</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="plans_about about_mmc">
          <Container className="h-100">
            <Row className="h-100">
              <Col lg={7} className="plans_about_text">
                <h1>Personalized Onboarding Support</h1>
                <p>To further enhance the user experience, MMC Convert offers a complimentary 30-minute onboarding support call for every completed conversion. Our team is dedicated to ensuring that users feel confident and equipped to utilize their newly migrated data effectively.</p>
              </Col>
              <Col lg={4} className="plans_about_img">
                <img className="img-fluid" alt="background" src={about03_img} />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="company_logos">
          <Container className="h-100">
            <Row className="h-100">
              <Col lg={12}>
                <div className="logo_img_head">
                  <h3>Exclusive Partnerships</h3>
                </div>
                <div className="logo_img_slide">
                  <img className='company-logo' alt="company-logo" src={logo_01} />
                  <img className='company-logo' alt="company-logo" src={logo_02} />
                  <img className='company-logo' alt="company-logo" src={logo_03} />
                  <img className='company-logo' alt="company-logo" src={logo_04} />
                  <img className='company-logo' alt="company-logo" src={logo_05} />
                  <img className='company-logo' alt="company-logo" src={logo_06} />
                  <img className='company-logo' alt="company-logo" src={logo_07} />
                  <img className='company-logo' alt="company-logo" src={logo_01} />
                  <img className='company-logo' alt="company-logo" src={logo_02} />
                  <img className='company-logo' alt="company-logo" src={logo_03} />
                  <img className='company-logo' alt="company-logo" src={logo_04} />
                  <img className='company-logo' alt="company-logo" src={logo_05} />
                  <img className='company-logo' alt="company-logo" src={logo_06} />
                  <img className='company-logo' alt="company-logo" src={logo_07} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pricing_cards recognition_section">
          <Container className="h-100">
            <Row>
              <Col lg={12}>
                <h3 className="history_title">Recognition</h3>
                <p>MMC Convert takes pride in being recognized as #245 among America's fastest-growing companies by Inc 5000. This acknowledgment underscores our commitment to excellence and continuous growth in providing exceptional data conversion services.</p>
                <p>Whether you're a small startup or a large enterprise, MMC Convert is your trusted partner for seamless data migration from Excel to Xero. Experience the difference with MMC Convert today.</p>
              </Col>
              <Col lg={12} className="d-flex justify-content-center">
                <img className="img-fluid" alt="background" src={about04_img} />
              </Col>
            </Row>
          </Container>
        </section> */}
      <section className="plans_about plans_contact_us about_contact_us">
        <Container className="h-100">
          <Row className="h-100 d-flex align-items-center">
            <Col lg={9} className="plans_about_text">
              <h1>Accelerate Your Transformation</h1>
              <p>
                Transform your workflow with lightning-fast, precise file
                conversions today!
              </p>
            </Col>

            <Col lg={3} className="plans_about_img d-flex justify-content-end">
              <Button className="btn" onClick={() => navigate(loginType ? `/${loginType}/login` : '/login')}>
                Convert Now
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Contactus;
