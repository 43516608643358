import { Progress } from "reactstrap";

const ProgressControl = (props: any) => {

    const { color,value} = props;
    let progressValue=undefined
    if(props.rowData.jobRunningStatus)
    {
      try{
        
        progressValue=Math.round((Number(JSON.parse(props.rowData.jobRunningStatus).jobDonePercentage) + Number.EPSILON) * 100) / 100  
      }
      catch{

      }
      
    }


    return(<div className="progress-info">
    <span>{progressValue||0}% completed</span>
    <Progress
      animated
      color={color||"info"}
      value={progressValue||0}
      style={{
        height: '8px'
      }}
    />
  </div>)
};
export { ProgressControl };
