import { useRef, useState } from "react"
import { deleteIcon, file, fileupload } from "../../../../../constant/icons"
import { FileUpload } from "../../../type"
import { message } from "../../../../../components/toast"
import { ACCEPTS_ONLY } from "../../../message"
import { Button } from "reactstrap"
const FileAccept = ["text/csv", "csv", "CSV","xlsx"]
const maxSize = 3 * 1024 * 1024;
const BrowserFile = (props: any) => {
    const { fileUpload, onFileUpdate, onFileUpdateRefresh } = props
    const fileInputRef:any = useRef(null);
    const onRemoveFile = (index: number) => {
        delete fileUpload[index]
        if(fileInputRef.current){
            fileInputRef.current.value = null
        }
        
        onFileUpdate(fileUpload.filter((x: any) => x !== undefined))
        onFileUpdateRefresh(true)
    }
    const addFiles = (e: any) => {
        if (e?.target?.files) {
            let UploadFile: FileUpload[] = []
            let invalidFile = false
            let tooBigFile= false
            for (let x = 0; x < e.target.files.length; x++) {
                if(e.target.files[x].size > maxSize){
                    tooBigFile=true;
                }else if (FileAccept.includes(e.target.files[x].name.split('.').pop())) {
                    UploadFile.push({
                        file: e.target.files[x],
                        selectedSheet: []
                    })
                }
                else {
                    invalidFile = true
                }
            }
            if(tooBigFile){
                message('warning',"Your file size is too large. Please split it into smaller chunks and try again. Each file should not exceed 3 MB.")
            }
            else if (invalidFile) {
                message('warning', ACCEPTS_ONLY)
            }
            onFileUpdate(UploadFile)
            e.target.files = null;
            if(fileInputRef.current){
                fileInputRef.current.value = null
            }
            onFileUpdateRefresh(true)
        }
    }
    return (<div className="dropzone mb-3">
        <label
            htmlFor="browserfile"
            className="dropzone-container"
        >
            <div className="file-icon">
                {(!fileUpload?.length || fileUpload?.length <= 0) && fileupload.icon}
            </div>
            {(!fileUpload?.length || fileUpload?.length <= 0) && <><div className="dropzone-title">
                Drag and drop your files or
                <span className="browse"> browse</span> your files
            </div></>}
            <div className="attachment-data-list">
                {fileUpload.map((item: FileUpload, index: number) => {
                    return <div className="attachment-data">
                        <a title="File" className="file-box">
                            {file.icon}
                            <p>{item.file.name}</p>
                        </a>
                        <Button className="remove-box" title="remove" onClick={(e) => {
                            e.preventDefault();
                            onRemoveFile(index)
                        }}> {deleteIcon.icon}</Button>

                    </div>
                })}
            </div>
        </label>

        <input
            ref={fileInputRef}
            id="browserfile"
            name="browserfile"
            type="file"
            className="form-control"
            multiple
            onChange={addFiles}
        />

    </div>)
}
export { BrowserFile }