import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useApi } from "../../helper/apiHook";
import { DataView } from "../../components/table/table";
import { jobErrorColumns } from "./columns";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { useEffect } from "react";

const JobErrorView = (props: any) => {
  const { jobPayloadId, toggleErrorLog } = props;
  const api = useApi("jobErrorDetailsList", true, {
    jobPayloadId: jobPayloadId,
  });

  const loader = useLoaderHelper();

  useEffect(() => {
    if (api.data?.length == 0) {
      toggleErrorLog();
    }
  }, [api]);

  return (
    <>
      <Modal
        className="job-modal job_error_details"
        toggle={toggleErrorLog}
        isOpen={true}
        size="xl"
        centered
        scrollable={true}
      >
        <ModalHeader toggle={toggleErrorLog}>
          {/* {openJobId.jobName} */}
          <span>
            Total: <b>{api.rowCount}</b>
          </span>
        </ModalHeader>
        <ModalBody>
          <DataView
            columns={jobErrorColumns({ api, loader })}
            rows={api.data}
            rowCount={api.rowCount}
            currentPage={api.currentPage}
            limit={api.limit}
            hideRowHeader={true}
            updatePagination={api.updatePagination}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
export { JobErrorView };
