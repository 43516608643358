import { Outlet, useLocation } from "react-router-dom";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Header } from "./header";
import { Loader } from "./loader";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { SubscriptionView } from "../company/subscription";
import { useEffect, useState } from "react";
import { useApp } from "../../components/app";
import { TrialBanner } from "../../components/TrialBanner/TrialBanner";
import { useAuth } from "../../components/auth";

export const Layout = () => {
  const App = useApp()
  const loading = useLoaderHelper();
  const location = useLocation();
  const auth = useAuth()

  const [subscription, setSubscription] = useState(false);
  const isSubscribe = JSON.parse(sessionStorage.getItem('token') as any)?.isSubscribe
  const PlanToggle = () => {
    setSubscription(false)
  }

  const [showTrialBanner, setShowTrialBanner] = useState(true);
  const onClose = () => {
    setShowTrialBanner(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTrialBanner(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location?.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSubscription(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading.dataIsLoading && <Loader></Loader>}
      {/* {App?.currentApp?.appCode && !isSubscribe && <SubscriptionView subscription={subscription} PlanToggle={PlanToggle} />} */}
      {/* {showTrialBanner && auth?.user?.role != 'Admin' && < TrialBanner onClose={onClose} />} */}

      <main className="theme-01 sidebar-collapse">
        <Header></Header>
        <Aside></Aside>
        <main className="main">
          <Outlet />
          <Footer></Footer>
        </main>
      </main>
    </>
  );
};
