import { useApp } from "../../components/app";
import { useNavigate } from "react-router-dom";
import { WizardBox } from "../job/addUpdate/wizardBox/layout";
import { useApi } from "../../helper/apiHook";

const ModifictionList = () => {
    const app = useApp()
    const api = {}
    // const api = useApi('job',false,{isUpdate:true})
    let navigate = useNavigate()
    if (!app.conversation) {
        navigate('/')
    }
    const closeAddUpdate = () => {

    }
    return (<WizardBox
        api={api}
        isUpdate={true}
        closeAddUpdate={closeAddUpdate}
    />);
}
export { ModifictionList }