export const getDate = (value: any) => {
  
  try {
    if(value!=undefined&&value!=null)
    {
        return new Date(value);
    }
    
  } catch(error) {
console.log("dateError",error)
  }
  return undefined;
};
