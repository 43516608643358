import { Button, Col, FormGroup, Row } from "reactstrap"
import PageLayout from "../layout/page"
import { arrowLeft, arrowRight, deleteIcon, file, fileupload } from "../../constant/icons"
import ReactSelect from "react-select"
import { useRef, useState } from "react"
import { BalanceSheetView } from "./balanceSheetView"
import { getSheetNames } from "../job/addUpdate/wizardBox/utility"

const BalanceSheet = () => {
  const [view, setView] = useState(false)
  const [uploadFile, setUploadFile] = useState<any>()
  const [columnOptions, setColumnOptions] = useState<any>()
  const fileInputRef: any = useRef(null);
  const addFiles = async (e: any) => {
    if (e?.target?.files) {
      let sheetList = await getSheetNames({ fileUpload: [{ file: e?.target?.files[0] }] })
      if (sheetList && Array.isArray(sheetList) && sheetList[0].totalSheet&&sheetList[0].totalSheet[0]?.sourceColumnList) {
        setColumnOptions(sheetList[0].totalSheet[0]?.sourceColumnList[0]?.map((item:any) => {
          return {
            value: item,
            label: item
          }
        }))
      }

      setUploadFile(sheetList)
    }
  }
  return view ? <BalanceSheetView /> : <PageLayout
    title={
      <>
        <Button color="link" className="btn-back">{arrowLeft.icon} </Button>Balance Sheet
      </>}
    View={<>
      <Row>
        <Col lg={12} className="mb-4">
          <div className="dropzone mb-3">
            <label
              htmlFor="browserfile"
              className="dropzone-container"
            >
              <div className="file-icon">
                {fileupload.icon}
              </div>
              <>
                <div className="dropzone-title">
                  Drag and drop your files or
                  <span className="browse"> browse</span> your files
                </div>
              </>
              {uploadFile && <div className="attachment-data-list">
                <div className="attachment-data">
                  <a title="File" className="file-box">
                    {file.icon}
                    <p>{uploadFile[0]?.file?.name}</p>
                  </a>
                  <a className="remove-box" title="remove">
                    {deleteIcon.icon}
                  </a>
                </div>
              </div>}

            </label>

            <input
              ref={fileInputRef}
              id="browserfile"
              name="browserfile"
              type="file"
              className="form-control"

              onChange={addFiles}
            />

          </div>
        </Col>
        <Col lg={12} className="column-maping-box blance_maping_box">
          <div className="column-maping-list-data">
            <div>
              <ul className="column-header">
                <li>Destination Column</li>
                <li>Source Column</li>
                <li>Remark</li>
                <li></li>
              </ul>
              <div className="column-body small-scroll">
                <div className="column-data-list">
                  <label>Account Code<span>*</span></label>
                  <FormGroup className="form-floating">
                    <ReactSelect
                      className="column_field_select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      menuPosition={'fixed'}
                      isClearable={true}
                      options={columnOptions}
                      placeholder={<div>Select column field</div>}
                    />
                  </FormGroup>

                </div>
                <div className="column-data-list">
                  <label>Date<span>*</span></label>
                  <FormGroup className="form-floating">
                    <ReactSelect
                      className="column_field_select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      menuPosition={'fixed'}
                      isClearable={true}
                      options={columnOptions}
                      placeholder={<div>Select column field</div>}
                    />
                  </FormGroup>

                </div>
                <div className="column-data-list">
                  <label>Amount<span>*</span></label>
                  <FormGroup className="form-floating">
                    <ReactSelect
                      className="column_field_select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      menuPosition={'fixed'}
                      isClearable={true}
                      options={columnOptions}
                      placeholder={<div>Select column field</div>}
                    />
                  </FormGroup>

                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => setView(true)} title="Next" type="submit">Next{arrowRight.icon}</button>
        </Col>
      </Row>
    </>}
  />
  // item={}

}


export { BalanceSheet }