import PageLayout from "../layout/page";
import { arrowLeft } from "../../constant/icons";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import { formatDateForApi, useApp } from "../../components/app";
import { useNavigate } from "react-router-dom";

import { DataView } from "../../components/table/table";
import { ErrorMessage, Field, Form, Formik } from "formik";
import reactSelect from "react-select";
import ReactDatePicker from "react-datepicker";
import { useApi } from "../../helper/apiHook";
import { useEffect, useMemo, useState } from "react";
import { ActionType } from "ka-table";
import { post } from "../../helper/api";
import { useLoaderHelper } from "../../helper/loaderHelper";
import { getDate } from "../../helper/utility";

const DeleteList = (props: any) => {
  const app = useApp();
  const [filters, setFilters] = useState<any>({});
  const loader = useLoaderHelper();
  const api=useApi("getValues", true, filters,{
    revalidateOnFocus: true,
      revalidateIfStale: true,
  });
  const { isDeleteByReference } = props;
  // let selectedId: string[] = []
  const [viewDelete, setViewDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<any[]>([]);
  let navigate = useNavigate();
  if (!app.conversation) {
    navigate("/");
  }
  const resendColumns = useMemo(() => {
    if (api?.data?.data?.columnsList) {
      let column: any = (api?.data?.data?.columnsList as []).filter(
        (x: any) => x.actionKey
      );
      if (column) {
        column = column?.map((item: any) => item.key);
        return column;
      }
      return ["id"];
    }
    return ["id"];
  }, [api?.data?.data?.columnsList]);

  const dataOfRow = useMemo(() => {
    if (api?.data?.data?.data && Array.isArray(api?.data?.data?.data)) {
      return api?.data?.data?.data.map((item: any, index: any) => {
        item.tableId = index;
        return item;
      });
    }
    setSelectedId([]);
    return [];
  }, [api?.data?.data?.data]);

  const onSelectionChange = (selectedType: string, rowId: string) => {
    if (selectedType === ActionType.SelectRow) {
      let row = dataOfRow?.find((item: any) => item["tableId"] == rowId);

      if (row) {
        let values: any = { tableId: rowId };
        resendColumns.forEach((item: any) => {
          values[item] = row[item];
        });

        setSelectedId((prevSelectedId) => [...prevSelectedId, values]);
      }
    } else if (selectedType === ActionType.DeselectRow) {
      setSelectedId((prevSelectedId) => [
        ...prevSelectedId.filter((x: any) => x.tableId != rowId),
      ]);
    } else if (selectedType === ActionType.SelectAllFilteredRows) {
      let row = dataOfRow;
      if (row) {
        let newSelectedId: any[] = [];
        row.forEach((element: any) => {
          let values: any = {};
          resendColumns.forEach((item: any) => {
            values[item] = element[item];
          });
          newSelectedId.push(values);
        });
        setSelectedId(newSelectedId);
      }
    } else if (selectedType === ActionType.DeselectAllFilteredRows) {
      setSelectedId([]);
    }
  };
  useEffect(() => {
    console.log("selectedIduseEffect", selectedId);
    setViewDelete(selectedId.length > 0);
  }, [selectedId.length, selectedId]);

  const onDeleteDate = async (functionCode: string | undefined) => {
    if (functionCode) {
      const resultOfDeleteValues = await post("/deleteValues",
        {
          functionCode: functionCode,
          tenantId: app.conversation?.tenant?.[0]?.id || 0,
          Ids: selectedId,
        },undefined,loader);
        console.log("resultOfDeleteValues",resultOfDeleteValues)
        if(resultOfDeleteValues?.status){
          navigate('/job')
        }else{
          api?.mutate();
        }
      
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          function: undefined,
          dateType: undefined,
          fromDate: undefined,
          toDate: undefined,
        }}
        // validationSchema={companyValidationSchema}
        onSubmit={(values, actions) => {
          console.log("values", values);
          setFilters({
            functionCode: values.function,
            dateType: values.dateType,
            tenantId: app.conversation?.tenant?.[0]?.id || 0,
            fromDate: values.fromDate,
            toDate: values.toDate,
          });

          api.mutate()
        }}
      >
        {(formProps) => (
          <Form className="mx-0 delete_search mb-3">
            <Row>
              <Col lg={3}>
                <FormGroup className="form-floating sticky_label mb-0">
                  <Field
                    name="function"
                    component={reactSelect}
                    className="column_field_select"
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    menuPosition={"fixed"}
                    onChange={(newValue: any) => {
                      formProps.setFieldValue("function", newValue.value);
                    }}
                    options={app.functionDropDownList()}
                    placeholder={<div>Select column field</div>}
                  />
                  <ErrorMessage
                    name="function"
                    component="div"
                    className="text-danger"
                  />
                  <Label>Function List</Label>
                </FormGroup>
              </Col>
              <Col lg={3}>
                <FormGroup className="form-floating sticky_label mb-0">
                  <Field
                    name="dateType"
                    component={reactSelect}
                    className="column_field_select"
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    menuPosition={"fixed"}
                    onChange={(newValue: any) => {
                      formProps.setFieldValue("dateType", newValue.value);
                    }}
                    options={[
                      {
                        value: "create",
                        label: "Create Date",
                      },
                      {
                        value: "update",
                        label: "Update Date",
                      },
                    ]}
                    placeholder={<div>Select column field</div>}
                  />
                  <ErrorMessage
                    name="dateType"
                    component="div"
                    className="text-danger"
                  />
                  <Label>Date Type</Label>
                </FormGroup>
              </Col>
              <Col lg={3}>
                <FormGroup className="form-floating sticky_label mb-0 delete_reference_date">
                  <Field
                    name="fromDate"
                    component={ReactDatePicker}
                    placeholderText={"DD/MM/YYYY"}
                    dateFormat={"dd/MM/yyyy"}
                    className="form-control w-100"
                    selected={getDate(formProps.values?.fromDate)}
                    onChange={(date: any, _: any) => {
                      formProps.setFieldValue("fromDate", date);
                    }}
                  />
                  <ErrorMessage
                    name="fromDate"
                    component="div"
                    className="text-danger"
                  />
                  <Label>
                    From Date<span style={{ color: "red" }}>*</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col lg={3}>
                <FormGroup className="form-floating sticky_label mb-0 delete_reference_date">
                  <Field
                    component={ReactDatePicker}
                    placeholderText={"DD/MM/YYYY"}
                    dateFormat={"dd/MM/yyyy"}
                    className="form-control"
                    name="toDate"
                    selected={getDate(formProps.values?.toDate)}
                    onChange={(date: any, _: any) => {
                      formProps.setFieldValue("toDate", date);
                    }}
                  />
                  <ErrorMessage
                    name="toDate"
                    component="div"
                    className="text-danger"
                  />
                  <Label>
                    To Date<span style={{ color: "red" }}>*</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col lg={10} className="d-flex justify-content-end">
                {viewDelete && (
                  <Button
                    onClick={() => onDeleteDate(formProps?.values?.function)}
                  >
                    Delete Selected
                  </Button>
                )}
              </Col>

              <Col lg={2} className="d-flex justify-content-end">
                <Button>Search</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {console.log(
        "api?.data?.data?.columnsList ",
        api?.data?.data?.columnsList
      )}
      <DataView
        columns={api?.data?.data?.columnsList || []}
        rows={dataOfRow}
        rowCount={api?.rowCount}
        currentPage={api?.currentPage}
        limit={api?.limit}
        keyId={"tableId"}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};
export { DeleteList };
