import {
  Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap"
import plans_bg_color from '../../src/assets/images/hero_bg.png'
import plans_bg_noice from '../../src/assets/images/hero_Noise.png'
import about01_img from '../assets/images/about-us01.png'
import about02_img from '../assets/images/about02.png'
import about03_img from '../assets/images/about03.png'
import about04_img from '../assets/images/about04.png'
import { arrowlineIcon, checkarrowIcon } from "../constant/icons"
import logo_01 from '../assets/images/xero_logo-ex.png'
import logo_02 from '../assets/images/intuit_quickbooks_logo-ex.png'
import logo_03 from '../assets/images/freshbooks-logo-1.png'
import logo_04 from '../assets/images/myob_logo.png'
import logo_05 from '../assets/images/reckon-logo.png'
import logo_06 from '../assets/images/netsuite-logo.png'
import logo_07 from '../assets/images/odoo-logo.png'
import how_towork01 from '../assets/images/loading_file.png'
import how_towork02 from '../assets/images/SelectService.png'
import how_towork03 from '../assets/images/MakePayment.png'
import how_towork04 from '../assets/images/Leavefile.png'
import how_towork05 from '../assets/images/SubscriptionTransfer.png'
import { useState } from "react"
import { Header } from "./layout/userheader"
import { useLocation, useNavigate } from "react-router-dom"

const About = () => {
  const currentLocation = useLocation()
  let loginType: string | undefined | null = currentLocation.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo", "XERO"].includes(loginType)) {
    loginType = undefined
  }
  console.log("loginType==", loginType)
  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const [open, setOpen] = useState<any>('');
  const navigate = useNavigate()
  const toggle = (id: any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Header />
      <section className="plans_hero">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={7} className="plans_main_text">
              <h1>Our<span> Story</span></h1>
              <p>Crafting Stories, Inspiring Connections</p>
            </Col>
          </Row>
        </Container>
        <img className='plans_bg_noice' alt="background" src={plans_bg_noice} />
        <img className='plans_bg_color' alt="background" src={plans_bg_color} />
      </section>
      <section className="plans_about about_mmc">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={6} className="plans_about_img">
              <img className="img-fluid" alt="background" src={about01_img} />
            </Col>
            <Col lg={6} className="plans_about_text">
              <h1>About MMC Convert</h1>
              <p>At MMC Convert, we specialize in assisting users in converting their historical data from Excel (csv file format) to Xero seamlessly. Our mission is to simplify the process of migrating data, ensuring accuracy and reliability every step of the way.</p>
            </Col>
          </Row>
          <Row className="h-100">
            <Col lg={6} className="plans_about_text">
              <h1>Our Approach</h1>
              <p>MMC Convert leverages in-house tools and expertise to provide top-notch data migration services. We understand the importance of preserving transactional history, especially across multiple historical years, and our solutions are tailored to meet this need effectively.</p>
            </Col>
            <Col lg={6} className="plans_about_img approach_img">
              <img className="img-fluid" alt="background" src={about02_img} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pricing_cards Comprehensive_section">
        <Container className="h-100">
          <Row>
            <Col lg={12}>
              <h3 className="history_title clip_title">Comprehensive <span>Conversion</span> Capabilities</h3>
              <p>With MMC Convert, businesses gain access to a comprehensive suite of conversion capabilities. Our services cover the import of various functions within Xero, including but not limited to:</p>
            </Col>
            <Col lg={12}>
              <ul>
                <li>
                  <span>Chart of Accounts</span>
                </li>
                <li>
                  <span>Customer Master</span>
                </li>
                <li>
                  <span>Vendor Master</span>
                </li>
                <li>
                  <span>Tracking Classes</span>
                </li>
                <li>
                  <span>Conversion Balance</span>
                </li>
                <li>
                  <span>Tax Master</span>
                </li>
                <li>
                  <span>Item Master</span>
                </li>
                <li>
                  <span>Invoices</span>
                </li>
                <li>
                  <span>Bills</span>
                </li>
                <li>
                  <span>Credit Notes</span>
                </li>
                <li>
                  <span>Invoice Payments</span>
                </li>
                <li>
                  <span>Bill Payments</span>
                </li>
                <li>
                  <span>Credit Note Allocation</span>
                </li>
                <li>
                  <span>Overpayments</span>
                </li>
                <li>
                  <span>Purchase Orders</span>
                </li>
                <li>
                  <span>Sales Estimates</span>
                </li>
                <li>
                  <span>Reconciled Bank Transactions</span>
                </li>
                <li>
                  <span>Manual Journal Entries</span>
                </li>
                <li>
                  <span>Trial Balance</span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="plans_about about_mmc">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={7} className="plans_about_text">
              <h1>Personalized Onboarding Support</h1>
              <p>To further enhance the user experience, MMC Convert offers a complimentary 30-minute onboarding support call for every completed conversion. Our team is dedicated to ensuring that users feel confident and equipped to utilize their newly migrated data effectively.</p>
            </Col>
            <Col lg={4} className="plans_about_img">
              <img className="img-fluid" alt="background" src={about03_img} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="company_logos">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={12}>
              <div className="logo_img_head">
                <h3>Exclusive Partnerships</h3>
              </div>
              <div className="logo_img_slide">
                <img className='company-logo' alt="company-logo" src={logo_01} />
                <img className='company-logo' alt="company-logo" src={logo_02} />
                <img className='company-logo' alt="company-logo" src={logo_03} />
                <img className='company-logo' alt="company-logo" src={logo_04} />
                <img className='company-logo' alt="company-logo" src={logo_05} />
                <img className='company-logo' alt="company-logo" src={logo_06} />
                <img className='company-logo' alt="company-logo" src={logo_07} />
                <img className='company-logo' alt="company-logo" src={logo_01} />
                <img className='company-logo' alt="company-logo" src={logo_02} />
                <img className='company-logo' alt="company-logo" src={logo_03} />
                <img className='company-logo' alt="company-logo" src={logo_04} />
                <img className='company-logo' alt="company-logo" src={logo_05} />
                <img className='company-logo' alt="company-logo" src={logo_06} />
                <img className='company-logo' alt="company-logo" src={logo_07} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pricing_cards recognition_section">
        <Container className="h-100">
          <Row>
            <Col lg={12}>
              <h3 className="history_title">Recognition</h3>
              <p>MMC Convert takes pride in being recognized as #245 among America's fastest-growing companies by Inc 5000. This acknowledgment underscores our commitment to excellence and continuous growth in providing exceptional data conversion services.</p>
              <p>Whether you're a small startup or a large enterprise, MMC Convert is your trusted partner for seamless data migration from Excel to Xero. Experience the difference with MMC Convert today.</p>
            </Col>
            <Col lg={12} className="d-flex justify-content-center">
              <img className="img-fluid" alt="background" src={about04_img} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="plans_about plans_contact_us about_contact_us">
        <Container className="h-100">
          <Row className="h-100 d-flex align-items-center">
            <Col lg={9} className="plans_about_text">
              <h1>Accelerate Your Transformation</h1>
              <p>Transform your workflow with lightning-fast, precise file conversions today!</p>
            </Col>
            <Col lg={3} className="plans_about_img d-flex justify-content-end">
              <Button className="btn" onClick={() => navigate(loginType ? `/${loginType}/login` : '/login')}>Convert Now</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default About