import { useState } from "react";
import { Button, ListGroup, ListGroupItem, ListGroupItemText } from "reactstrap";
import { JEditor } from "../jsonEditor";
import { edit } from "../../../constant/icons";
import { post } from "../../../helper/api";

const ErrorLogView = (props: any) => {
    const {rowData,column} = props;
    
    const [editModeId, setEditModeId] = useState(null)
    const [editedPayLoad, setEditedPayLoad] = useState(rowData.payload)
    const PayLoadRePost =async (item: any) => {
        console.log("item data A")
        if (editedPayLoad&&column&&column.callBack) {
            column.callBack({ errorId: item.id, payload: JSON.stringify(editedPayLoad) })
           
            
        //   console.log("item data C",response)
        }
      }
    return (<ListGroup className="error_detail">
        <ListGroupItem>
            <ListGroupItemText>
                <p><b>Message:</b>{rowData.errorMessage}</p>
                <p><b>Details:</b>{rowData.errorDtl}</p>
                <div className="json-log">
                    {editModeId && editModeId === rowData.id ? <></> : <Button color="link" title="Edit JSON" onClick={() => setEditModeId(rowData.id)} className="">
                        {edit.icon}
                    </Button>}
                    {editModeId && editModeId === rowData.id ? <JEditor id={`JEditor${0}`} data={editedPayLoad} setJsonData={setEditedPayLoad} /> : <pre>{rowData.payload}</pre>}
                </div>
            </ListGroupItemText>
            {editModeId && editModeId === rowData.id && <>
                <div className="d-flex justify-content-center gap-2 mt-2">
                    <Button className="save edit_error_bottom_btn btn-secondry" onClick={() => PayLoadRePost(rowData)} type="button">Save</Button>
                    <Button className="cancle edit_error_bottom_btn btn-outline-danger" onClick={() => setEditModeId(null)} type="button">Cancel</Button>
                </div>
            </>}
        </ListGroupItem>
    </ListGroup>)
};
export { ErrorLogView };