import { Button } from "reactstrap";
import { ProgressControl } from "../../../components/progressBar";
import { confirmBox } from "../../../components/toast";
import { JobStatus } from "../type";
import { START_JOB } from "../message";
import { errorIcon, rightIcon } from "../../../constant/icons";

const ProgressStatusLabel = (props: any) => {
    const {column, rowData} = props;
    switch(rowData.jobStatus){
        case JobStatus.DONE.id:{
            return  <div className="progress-info completed">
            <span className="text-success">{rightIcon.icon}completed</span>
          </div>
        }
        case JobStatus.DONE_WITH_ERROR.id:{
            return <h6 className="error_label">{errorIcon.icon}<span>{rowData.jobStatusLabel}</span></h6>
        }
        case JobStatus.ERROR.id:{
            return <h6 className="error_label">{errorIcon.icon}<span>{rowData.jobStatusLabel}</span></h6>
        }
        case JobStatus.IN_PROGRESS.id:{
            return <ProgressControl {...props}/>
        }
        case JobStatus.IN_PROGRESS_RE_POST.id:{
            return <h6>{rowData.jobStatusLabel}</h6>
        }
        case JobStatus.PENDING.id:{
            return <Button className="btn-sm btn btn-success" onClick={async () => {
                const response = await confirmBox(START_JOB);
                if (response && column.callBack) {
                    column.callBack(rowData)
                }
            }}>Start Process</Button>
        }
        case JobStatus.READY_TO_PROCESS.id:{
            return <h6>{rowData.jobStatusLabel}</h6>
        } 
        default:{
            return <h6>{rowData.jobStatusLabel}</h6>
        }
    }
};

export { ProgressStatusLabel };