import { DataType } from 'ka-table/enums';
import { TableColumn } from '../../components/table/table';
import { StatusControl } from '../../components/buttons/status';
import { useApiType } from '../../helper/apiHook';



export const columns=(actions:any):TableColumn[]=>[
    { 
        key: 'id', 
        title: 'id', 
        dataType: DataType.Number,
        visible:false
    },
    { 
        key: 'account', 
        title: 'Account Code', 
        dataType: DataType.String,
        
        callBack:(row)=>{
            
        }
        
    },
    { 
        key: 'accountName', 
        title: 'Account Name', 
        dataType: DataType.String,
        callBack:(row)=>{
            
        }
    },
    
    { 
        key: 'accountCreditUser', 
        title: 'Credit Amount', 
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            
        },
        style: {
            textAlign: 'center'
        }
    },
    { 
        key: 'accountDebitUser', 
        title: 'Debit Amount', 
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            
        },
        style: {
            textAlign: 'center'
        }
    },
    { 
        key: 'accountCredit', 
        title: 'Xero Credit Amount', 
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            
        },
        style: {
            textAlign: 'center'
        }
    },
    { 
        key: 'accountDebit', 
        title: 'Xero Debit Amount', 
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            
        },
        style: {
            textAlign: 'center'
        }
    },
    { 
        key: 'accountDebitUserDiff', 
        title: 'Difference Amount', 
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            
        },
        style: {
            textAlign: 'center'
        }
    },
]