import { Button, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { confirmBox } from "../toast";
import { viewIcon } from "../../constant/icons";
import { useState } from "react";

const InfoControl = (props: any) => {

  const { disabled, column, rowData, value, message } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="link"
        className="text-primary"
        onClick={() => column.callBack({ row: rowData, info: true })}
        id="Viewtooltip"
      >
        {viewIcon.icon}
      </Button>
      <UncontrolledTooltip isOpen={tooltipOpen} target="Viewtooltip" toggle={toggleTooltip}>
        Function view
      </UncontrolledTooltip>
    </>
  );
};

export { InfoControl };