import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginFrom } from "../pages/auth/type";
import { get, post } from "../helper/api";
import { useApp } from "./app";
import { useLoaderHelper } from "../helper/loaderHelper";

interface currentUser {
  name: string;
  role: string;
  token: string;
  loginType: string;
  isMaster: boolean
  isSubscribe: boolean
}

interface AuthContextValue {
  user: currentUser | null;
  login: (loginToke: LoginFrom) => void;
  logOut: () => void;
  singleSignOn: (token: any) => void;
  setUser: (user: currentUser | null) => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: () => { },
  logOut: () => { },
  singleSignOn: () => { },
  setUser: () => { },
});

export const getLoginUser = (userOb: any): currentUser | null => {
  try {
    if (!userOb) {
      return null;
    }
    return {
      role: userOb.role,
      name: userOb.userName,
      token: "",
      loginType: userOb?.loginFrom || userOb?.loginType,
      isMaster: userOb?.isMaster,
      isSubscribe: userOb?.isSubscribe
    } as currentUser;
  } catch { }
  return null;
};
export const getToken = (): string => {
  try {
    const token = sessionStorage.getItem("token");
    if (token && JSON.parse(token).token) {
      return JSON.parse(token).token as string;
    }
  } catch { }
  return "";
};
export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<currentUser | null>(
    getLoginUser(
      sessionStorage.getItem("token")
        ? JSON.parse(sessionStorage.getItem("token")!)
        : null
    )
  );
  const app = useApp();
  const navigate = useNavigate();
  const loader = useLoaderHelper();
  const login = async (loginFrom: LoginFrom) => {
    const loginToke = await post<LoginFrom>(
      "/login",
      loginFrom,
      undefined, loader
    );



    if (loginToke?.status && loginToke?.data[0]?.token) {
      if (loginToke?.data[0].role == "User") {

        if (loginFrom?.loginType) {
          let loginValue = {
            ...loginToke?.data[0],
            loginFrom: loginFrom?.loginType
          }
          singleSignOn(loginValue)
        } else {

          singleSignOn(loginToke?.data[0])
        }
        return false
      }
      sessionStorage.setItem("token", JSON.stringify(loginToke?.data[0]));
      setUser(getLoginUser(loginToke?.data[0]));
      navigate("/conversation");
      await app.refreshAppInfo();
      loader.setDataIsLoading(false);
    } else {
      loader.setDataIsLoading(false);
    }
  };

  const logOut = () => {
    let token:any=sessionStorage.getItem("token")
    try{
      token=JSON.parse(token)
       if(token&&token?.loginFrom&&token.loginFrom.length>2){
        token=token.loginFrom;
       }
       else{
        token=null;  
       }
    }catch{
      token=null;
    }


    if (user?.role === "User") {
      if (user?.loginType) {
        navigate(`/${user.loginType}/login`);
      }else if(token){
        navigate(`/${token}/login`);
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }  
    sessionStorage.clear();
    setUser(null);

    window.location.reload();
  };
  
  const singleSignOn = async (token: any) => {

    sessionStorage.setItem("token", JSON.stringify(token));
    setUser(getLoginUser(token));
    await app.refreshAppInfo();
    loader.setDataIsLoading(false);
    sessionStorage.setItem("appId", token?.loginFrom || token?.loginType)
    window.location.href = "/"
  }

  const forgotPassword = () => { };
  const resetPassword = () => { };
  const getAppList = async (): Promise<any[]> => {
    const AppList = await get("/appDtl", {}, loader);
    if (AppList?.status) {
      return AppList.data;
    }
    return [];
  };
  return (
    <AuthContext.Provider value={{ user, login, logOut, singleSignOn, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
