import { Button, FormGroup } from "reactstrap"
import { file, plus, reset, saveIcon, saveMapping } from "../../../../../constant/icons"
import ReactSelect from "react-select"
import { useEffect, useMemo, useState } from "react"
import { FileUpload, columnMap, sheetSet } from "../../../type"
import { COLUMNS_DETECTED } from "../../../message"
import { NewMap, UpdateMapChanges } from "../../addNewMap"
import Swal from "sweetalert2"
import { confirmBox } from "../../../../../components/toast"

const ColumnsMap = (props: any) => {
  const { selectedMap, resetId, setSelectedMap, fileUpload, onFileUpdate, refresh, app } = props
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [askNewMap, setAskNewMap] = useState<boolean>(false)

  useEffect(() => {
    if (resetId) {
      confirmBox("Do you want to clear", "clear map").then((result) => {
        if (result) {
          const columnMap = fileUpload[currentFileIndex]?.selectedSheet?.[0]?.columnMap;
          if (columnMap) {
            for (let yo = 0; yo < columnMap.length; yo++) {
              columnMap[yo].Source = ""
            }
            onFileUpdate(fileUpload)
          }
        }
      })
    }
  }, [resetId])
  const mapingMainHead = useMemo(() => <ul className="maping-main-head">
    <li>
      <label>File name</label>
      <span>{fileUpload[currentFileIndex].file.name}</span>
    </li>
    <li>
      <label>Sheet name</label>
      <span>{fileUpload[currentFileIndex]?.selectedSheet?.[0]?.name ?? ""}</span>
    </li>
    <li>
      <label>Function name</label>
      <span>{app.getSelectedFunctionItem(fileUpload[currentFileIndex]?.selectedSheet?.[0]?.functionCode)?.label ?? ""}</span>
    </li>

    <li className="ms-auto">
      <FormGroup className="form-floating">
        <ReactSelect
          menuPlacement="auto"
          isClearable={true}
          value={selectedMap}
          onChange={(newValue: any) => {
            if (newValue) {
              Swal.fire({
                title: 'Do you want to update Mapping?',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Only current File',
                denyButtonText: `Update all`,
                cancelButtonText: `Cancel`,
                width: '38rem'
              }).then((result) => {
                if (result.isConfirmed || result.isDenied) {
                  Swal.fire({
                    title: 'Would you like to overwrite mapped columns?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: `No`,
                    cancelButtonText: `Cancel`,
                    showCancelButton: true,
                    width: '50rem'
                  }).then((resultOver) => {
                    for (let i = (result.isConfirmed ? currentFileIndex : 0); i < fileUpload.length; i++) {
                      const selectedSheet = fileUpload[i]?.selectedSheet?.[0];
                      if (selectedSheet) {
                        const currentFunctionMap = newValue.value.payloadMap.find((x: any) => x.function.functionCode === selectedSheet.functionCode)
                        if (currentFunctionMap && selectedSheet.columnMap && currentFunctionMap.payloadMap) {
                          (JSON.parse(currentFunctionMap.payloadMap) as any[]).forEach((item: any) => {
                            const column = selectedSheet.columnMap.find((x: any) => x.DestinationCode === item.DestinationCode)

                            if (column && (resultOver.isConfirmed || (column.Source === undefined || column.Source === null || column.Source.length <= 0))) {
                              try {
                                let valuesSource = fileUpload[i]?.totalSheet[0].sourceColumnList[0].find((cx: any) => cx.toString().trim().toUpperCase() === item.Source.toString().trim().toUpperCase())
                                if (valuesSource) {
                                  column.Source = valuesSource
                                }
                              } catch {

                              }


                            }
                          })
                        }
                      }
                      if (result.isConfirmed) {
                        break;
                      }
                    }
                    onFileUpdate(fileUpload)
                    setSelectedMap(newValue)
                  })
                }
              })

            }
            else {
              setSelectedMap(null)
            }
          }}
          options={app.getMapDropDownList()}
          placeholder={<div>Select mapped file</div>}
        />
      </FormGroup>
    </li>
    <li className="m-0 d-flex gap-3">
      {selectedMap && <Button className="btn btn-success btn-save" title="Save Mapping" onClick={
        async () => {
          if (selectedMap?.value?.id > 0) {
            const result = await UpdateMapChanges(selectedMap?.value?.id, "", NewMapCurrentFunction.id, NewMapCurrentFunction.columnMap)
            if (result && result.status) {
              await app.refreshAppInfo()

            }
          }
          else {
            setAskNewMap(true)
          }

        }} type="button">{saveIcon.icon}Save</Button>}

      <Button className="btn btn-danger btn-add" title="Add Mapping" onClick={() => setAskNewMap(true)} type="button">{plus.icon}Add</Button>
    </li>
  </ul>, [currentFileIndex, fileUpload, refresh, selectedMap, askNewMap])

  let convertSelectValue = (item: string | undefined) => {
    try {
      if (item && item.toString().trim().length > 0) {
        return {
          value: item.toString(),
          label: item.toString()
        }
      }
    }
    catch {

    }

    return undefined
  }

  let getColumnsList = useMemo(() => {
    const currentFile = fileUpload[currentFileIndex]
    const renderId = 1 + Math.random() * (100 - 1)
    if (currentFile) {
      const selectedSheet = currentFile?.selectedSheet?.[0]
      if (selectedSheet) {
        const columnMap = selectedSheet.columnMap
        if (columnMap && columnMap.length > 0) {
          return columnMap.map((item: columnMap, index: number) => {

            return <div key={`k${renderId}f${currentFileIndex}ks${index}`} className="column-data-list">
              <FormGroup id={`Gf${currentFileIndex}gs${index}`} className="form-floating">
                <ReactSelect
                  className="column_field_select"
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  menuPosition={'fixed'}
                  isClearable={true}
                  id={`f${currentFileIndex}s${index}`}
                  name={`f${currentFileIndex}s${index}`}
                  value={convertSelectValue(item.Source)}
                  placeholder={<div>Select column field</div>}
                  options={currentFile?.totalSheet?.find((x: sheetSet) => x.name === selectedSheet?.name)?.sourceColumnList?.[0].map((item: any) => {
                    return {
                      value: item,
                      label: item
                    }
                  })}
                  onChange={(newValue: any) => {
                    if (newValue && newValue.value) {
                      item.Source = newValue.value
                    }
                    else {
                      item.Source = ""
                    }
                    onFileUpdate(fileUpload)
                  }}
                />
              </FormGroup>
              <label className={item.requiredInfo ? "connector iscomment" : "connector"} >{item.DestinationLabel}{(item.isRequired || (item.requiredInfo && item.requiredInfo?.toString().trim().length > 1)) && <span style={{ color: item.isRequired ? 'red' : 'blue' }}>*</span>}</label>
              <span className="column-example">{item.requiredInfo}</span>
            </div>
          })
        }
      }
    }
    return <h6>{COLUMNS_DETECTED}</h6>
  }, [currentFileIndex, fileUpload, refresh])


  const mapToggle = () => {
    setAskNewMap(false)
  }
  const NewMapCurrentFunction = { columnMap: fileUpload[currentFileIndex]?.selectedSheet?.[0]?.columnMap, ...app.getFunction(fileUpload[currentFileIndex]?.selectedSheet?.[0]?.functionCode) }


  return (<div>
    {askNewMap && <NewMap
      app={app}
      currentFunction={NewMapCurrentFunction}
      mapToggle={mapToggle}
      isOpen={askNewMap}
    />}

    <div className="column-maping">

      <div className="column-maping-box">

        <ul className="column-file-list small-scroll">
          {fileUpload.map((fileItem: FileUpload, indexOfColumnMap: number) => {
            return <li className={indexOfColumnMap === currentFileIndex ? "active" : ""} onClick={() => setCurrentFileIndex(indexOfColumnMap)}>
              {file.icon}
              <p>{fileItem.file.name}</p>
            </li>
          })}
        </ul>

        <div className="column-maping-list-data">
          {mapingMainHead}
          <div>
            <ul className="column-header">
              <li>Source Column</li>
              <li>Destination Column</li>
              <li>MMC Remark</li>
              <li></li>
            </ul>
            <div className="column-body small-scroll">
              {getColumnsList}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>)
}
export { ColumnsMap }