import { DataView } from "../../components/table/table";
import { columns } from "./columns";
import { useApi } from "../../helper/apiHook";
import { Button } from "reactstrap";
import { AddUpdateUser } from "./addUpdate";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { plus } from "../../constant/icons";
import PageLayout from "../layout/page";
import { useEffect } from "react";
import { useAuth } from "../../components/auth";
export const UserAPI = () => {
  return { ...useApi("users", true) };
};

function UserList(props: any) {
  const api = UserAPI();
  const roleList = api.GetValue("/role", { rolePermissions: true });
  const auth = useAuth()
  const AddUpdate = useAddUpdate();
  useEffect(() => {
    api.mutate();
  }, []);

  const isMaster = auth.user?.role === 'Admin' ? false : auth.user?.isMaster || auth.user?.isSubscribe;

  return AddUpdate.viewAddUpdate ? (
    <AddUpdateUser
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
      roleList={roleList?.data}
    />
  ) : (
    <PageLayout
      title="User list"
      item={
        <>
          {(
            auth.user?.isMaster || auth.user?.isSubscribe
            //  auth.user?.role != "User"
          ) && <Button
            color="primary"
            onClick={() =>
              AddUpdate.openAddEditFrom({
                id: 0,
                name: "",
                emailId: "",
                mobileNo: "",
                roleId: (isMaster ? 2 : 1 || ""),
                userName: "",
                isMaster: isMaster || ""
              })
            }
          >
              {plus.icon}Add New
            </Button>
          }
        </>
      }
      View={
        <DataView
          columns={columns({ api, openAddEditFrom: AddUpdate.openAddEditFrom })}
          rows={api.data}
          updatePagination={api.updatePagination}
        />
      }
    />
  );
}

export default UserList;
