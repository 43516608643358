import { DataType } from 'ka-table/enums';
import { TableColumn } from '../../components/table/table';
import { StatusControl } from '../../components/buttons/status';
import { EditControl } from '../../components/buttons/edit';


export const columns=(actions:any):TableColumn[]=>{
    return[
    { 
        key: 'id', 
        title: 'id', 
        dataType: DataType.Number,
        visible:false
    },
    { 
        key: 'userName', 
        title: 'User Name', 
        dataType: DataType.String
    },
    { 
        key: 'name', 
        title: 'Name', 
        dataType: DataType.String
    },
    { 
        key: 'roleId', 
        title: 'Role', 
        dataType: DataType.Number,
        valueConvert:(row:any,rowData:any)=>{
          return rowData?.role?.name
        }
    },
    { 
        key: 'emailId', 
        title: 'Email Id', 
        dataType: DataType.String
    },
    { 
        key: 'mobileNo', 
        title: 'Mobile No', 
        dataType: DataType.String
    },
    { 
        key: 'isActivate', 
        title: 'Status', 
        hideHeaderFilter:true,
        dataType: DataType.Boolean,
        component:StatusControl,
        callBack:(row)=>{
            actions.api.status({
                id:row.id,
                isActivate:!row.isActivate
            })
        },
        style: {
            textAlign: 'center',
        }
    },
    { 
        key: 'edit', 
        title: '', 
        hideHeaderFilter:true,
        component:EditControl,
        callBack:(row)=>{
            actions.openAddEditFrom(row)
        },
        style: {
            textAlign: 'center',
        }
    },
]


}



