import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row,Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem } from "reactstrap"
import plans_bg_color from '../../src/assets/images/hero_bg.png'
import plans_bg_noice from '../../src/assets/images/hero_Noise.png'
import plans_about_img from '../assets/images/plans_img.webp'
import { arrowlineIcon, checkarrowIcon } from "../constant/icons"
import logo_01 from '../assets/images/xero_logo-ex.png'
import logo_02 from '../assets/images/intuit_quickbooks_logo-ex.png'
import logo_03 from '../assets/images/freshbooks-logo-1.png'
import logo_04 from '../assets/images/myob_logo.png'
import logo_05 from '../assets/images/reckon-logo.png'
import logo_06 from '../assets/images/netsuite-logo.png'
import logo_07 from '../assets/images/odoo-logo.png'
import how_towork01 from '../assets/images/loading_file.png'
import how_towork02 from '../assets/images/SelectService.png'
import how_towork03 from '../assets/images/MakePayment.png'
import how_towork04 from '../assets/images/Leavefile.png'
import how_towork05 from '../assets/images/SubscriptionTransfer.png'
import { useState } from "react"

const Price = () => {
  const [open, setOpen] = useState<any>('');
  const toggle = (id : any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };
 return(
    <>
    <section className="plans_hero">
        <Container className="h-100">
            <Row className="h-100">
                <Col lg={7} className="plans_main_text">
                    <h1>Plans and <span>Pricing</span></h1>
                    <p>Choose how to level up your productivity</p>
                </Col>
            </Row>
        </Container>
        <img className='plans_bg_noice' alt="background" src={plans_bg_noice} />
        <img className='plans_bg_color' alt="background" src={plans_bg_color} />
    </section>
    <section className="plans_about">
        <Container className="h-100">
            <Row className="h-100">
                <Col lg={6} className="plans_about_text">
                    <h1>Harness Cutting-Edge Automation Solutions for Maximum</h1>
                    <p>Transform your accounting with powerful automation tools and streamline your processes for efficiency and accuracy</p>
                </Col>
                <Col lg={6} className="plans_about_img">
                    <img className="img-fluid" alt="background" src={plans_about_img} />
                </Col>
            </Row>
        </Container>
    </section>
    <section className="how_to_work">
        <Container className="h-100">
          <Row> 
            <Col lg={12} className="d-flex justify-content-center">
              <h3 className="history_title">How It Works</h3>
            </Col>
          </Row>
          <Row className="h-100 d-flex justify-content-center py-5">
              <Col lg={12} className="d-flex justify-content-center ">
                <ul>
                  <li className="load_file">
                    <img className="img-fluid" alt="background" src={how_towork01} />
                    <h3>Load File</h3>
                    <span>{arrowlineIcon.icon}</span>
                  </li>
                  <li className="select_service">
                    <img className="img-fluid" alt="background" src={how_towork02} />
                    <h3>Select Service &<br></br> Provide Details</h3>
                    <span>{arrowlineIcon.icon}</span>
                  </li>
                  <li className="make_payment">
                    <img className="img-fluid" alt="background" src={how_towork03} />
                    <h3>Make Payment</h3>
                    <span>{arrowlineIcon.icon}</span>
                  </li>
                  <li className="leave_file">
                    <img className="img-fluid" alt="background" src={how_towork04} />
                    <h3>Leave file with MMC</h3>
                    <span>{arrowlineIcon.icon}</span>
                  </li>
                  <li className="receive_sub">
                    <img className="img-fluid" alt="background" src={how_towork05} />
                    <h3>Receive Subscription Transfer</h3>
                  </li>
                </ul>
              </Col>
          </Row>
        </Container>
    </section>
    <section className="pricing_cards">
        <Container className="h-100">
          <Row> 
            <Col lg={12} className="d-flex justify-content-center">
              <h3 className="history_title">Plans History</h3>
            </Col>
            <Col>
              <ul className="history_card">
                <li>
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5" className="m-0">
                      Unlimited
                      <span className="badge badge-success">Existing</span>
                      </CardTitle>
                      <div>
                        <p>23-08-2023 <b>to</b> 23-09-2023</p>
                      </div>
                      <h2 className="m-0">$600<span>/ month</span></h2>
                    </CardBody>
                  </Card>
                </li>
              </ul>
            </Col>
          </Row>
            <Row className="h-100 d-flex justify-content-center py-5">
                <Col lg={4} className="d-flex justify-content-center ">
                    <div className="switch">
                      <input
                        type="checkbox"
                        // checked={row.isActive}
                        // onChange={() => handleDeleteClick(row)}
                      />
                      <label
                        className="switch-check"
                        // onClick={() => {
                        //   handleDeleteClick(row);
                        // }}
                      >
                        <div className="switch-btn"></div>
                        <div className="active-switch">Annually</div>
                        <div className="deactive-switch">Monthly</div>
                      </label>
                    </div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={10}>
                    <div className="price_card"> 
                        <Card>
                          <CardBody>
                            <CardTitle tag="h5">
                            Free
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2"
                              tag="h6"
                            >
                              Get a taste of what MMC Converter can do for you.
                            </CardSubtitle>
                            <div>
                                <h2>$0<span>/ month</span></h2>
                            </div>
                            <Button>
                              Get Started
                            </Button>
                            <ul>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                            </ul>
                          </CardBody>
                        </Card>
                        <Card className="recommended_plan">
                          <CardBody>
                            <CardTitle tag="h5">
                            Plus
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2"
                              tag="h6"
                            >
                              Get a taste of what MMC Converter can do for you.
                            </CardSubtitle>
                            <div>
                                <h2>$50<span>/ month</span></h2>
                            </div>
                            <Button>
                              Get Started
                            </Button>
                            <ul>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                            </ul>
                            <span className="badge badge-warning">Recommended</span>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle tag="h5">
                            Unlimited
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2"
                              tag="h6"
                            >
                              Get a taste of what MMC Converter can do for you.
                            </CardSubtitle>
                            <div>
                                <h2>$600<span>/ month</span></h2>
                            </div>
                            <Button>
                              Get Started
                            </Button>
                            <ul>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                                <li>
                                    <span>
                                    {checkarrowIcon.icon}
                                    </span>
                                    <p><b>10</b> Lorem ipsum</p>
                                </li>
                            </ul>
                          </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    <section className="compare_plans">
        <Container className="h-100">
            <Row className="h-100">
                <Col lg={12} className="compare_plans_details"> 
                      <ul className="compare_plans_title">
                        <li className="compare_plans_head">
                          <h3>Compare our plans</h3>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                        <li>
                          <h4>Lorem ipsum</h4>
                          <p>Duis aute irure dolor in</p>
                        </li>
                      </ul>
                      <div className="compare_plans_check">
                        <ul>
                          <li className="plans_check_head">
                            <h4>Free</h4>
                            <h5>$0 USD /mo</h5>
                            <button className="btn">Select Plan</button>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                        </ul>
                        <ul>
                          <li className="plans_check_head">
                            <h4>Plus</h4>
                            <h5>$50 USD /mo</h5>
                            <button className="btn">Select Plan</button>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                        </ul>
                        <ul>
                          <li className="plans_check_head">
                            <h4>Unlimited</h4>
                            <h5>$600 USD /mo</h5>
                            <button className="btn">Select Plan</button>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                          <li>
                            <span>
                              {checkarrowIcon.icon}
                              </span>
                            <p><b>10</b> Lorem ipsum</p>
                          </li>
                        </ul>
                      </div>
                </Col>
            </Row>
        </Container>
    </section>
    <section className="company_logos">
        <Container className="h-100">
            <Row className="h-100">
                <Col lg={12}>
                    <div className="logo_img_head">
                      <h3>Exclusive Partnerships</h3>
                    </div>
                    <div className="logo_img_slide">
                      <img className='company-logo' alt="company-logo" src={logo_01} />
                      <img className='company-logo' alt="company-logo" src={logo_02} />
                      <img className='company-logo' alt="company-logo" src={logo_03} />
                      <img className='company-logo' alt="company-logo" src={logo_04} />
                      <img className='company-logo' alt="company-logo" src={logo_05} />
                      <img className='company-logo' alt="company-logo" src={logo_06} />
                      <img className='company-logo' alt="company-logo" src={logo_07} />
                      <img className='company-logo' alt="company-logo" src={logo_01} />
                      <img className='company-logo' alt="company-logo" src={logo_02} />
                      <img className='company-logo' alt="company-logo" src={logo_03} />
                      <img className='company-logo' alt="company-logo" src={logo_04} />
                      <img className='company-logo' alt="company-logo" src={logo_05} />
                      <img className='company-logo' alt="company-logo" src={logo_06} />
                      <img className='company-logo' alt="company-logo" src={logo_07} />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    <section className="plans_faq">
        <Container className="h-100">
            <Row className="h-100">
                <Col lg={3}>
                    <h3>Frequently Asked Questions</h3>
                </Col>
                <Col lg={9}>
                <Accordion className="plans_faq_accordians" flush open={open} >
                  <AccordionItem>
                    <AccordionHeader targetId="1">Lorem ipsum dolor sit amet, consectetur adipiscing </AccordionHeader>
                    <AccordionBody accordionId="1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="2">sed do eiusmod tempor incididunt ut labore et dolore magna</AccordionHeader>
                    <AccordionBody accordionId="2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">laboris nisi ut aliquip ex ea commodo</AccordionHeader>
                    <AccordionBody accordionId="3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                </Col>
            </Row>
        </Container>
    </section>
    <section className="plans_about plans_contact_us">
        <Container className="h-100">
            <Row className="h-100 d-flex align-items-center">
                <Col lg={9} className="plans_about_text">
                    <h1>Accelerate Your Transformation</h1>
                    <p>Transform your workflow with lightning-fast, precise file conversions today!</p>
                </Col>
                <Col lg={3} className="plans_about_img d-flex justify-content-end">
                    <Button className="btn">Convert Now</Button>
                </Col>
            </Row>
        </Container>
    </section>
    </>
    )   
}
export default Price