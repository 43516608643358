import * as Yup from "yup";

const companyValidationSchema = Yup.object().shape({
  
  name: Yup.string().required().max(100),
  email: Yup.string().email().required(),
  company_settings:Yup.object().shape({
    date: Yup.date().required("date is required"),
  }), 
  contact_person: Yup.array().of(
    Yup.object().shape({
      designation: Yup.string().required().max(100).required("designation is required"),
      name: Yup.string().required().max(100).required("name is required"),
      mobileNo: Yup.string().required().required("mobileNo is required"),
      email: Yup.string().email().required().required("email is required"),
    })
  ),
});

export { companyValidationSchema };
