
import { useState } from 'react';
import ReactJson from 'react-json-view'
const JEditor = (props) => {
    const {data,setJsonData}=props
    //const [jsonData, setJsonData] = useState(JSON.parse(props.data));
    
    return (
        <div key={props.id} >
            <ReactJson
                name={false}
                src={JSON.parse(data)}
                enableClipboard={true}
                iconStyle='circle'
                key={`JSONEditor${props.id}`}
                displayArrayKey={false}
                collapsed={true}
                displayDataTypes={false}
                validationMessage='check errors'
                onAdd={(event)=>{
                    setJsonData(JSON.stringify(event.updated_src))
                }}
                onEdit={(event) => {
                    setJsonData(JSON.stringify(event.updated_src))
                }}
                onDelete={(event)=>{
                    setJsonData(JSON.stringify(event.updated_src))
                }}
                displayObjectSize={false}
                indentWidth={10}
                quotesOnKeys={false}
                quotesOnvalues={false}
                shouldCollapse={true}
            />
        </div>)
}

export { JEditor }