import { DataView } from "../../components/table/table"
import { useApi } from "../../helper/apiHook"
import { AddUpdateRole } from "./addUpdate"
import { useAddUpdate } from "../../helper/addUpdateHook"
import { columns } from "./columns"
import { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik";
import { Button, Card, CardBody, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import { edit, editSolid } from "../../constant/icons"
import { RolePermissions } from "./permissions"


const RoleList = () => {
  const api = useApi('role');

  const [selectedRole, setSelectedRole] = useState<any>({
    id: 1,
    rolePermissions: [],
    name: "",
    initialValues: false
  })
  useEffect(() => {
    api.mutate()
  }, [])
  useEffect(() => {
    if (api.data && api.data?.length > 0) {
      const role = api.data.filter((x:any) => x.id === selectedRole.id)
      if (role) {
        setSelectedRole(role[0])
      }
      else {
        setSelectedRole(api.data[0])
      }
    }
  }, [api.data])
  const clearInitialValues = () => {
    setSelectedRole({ ...selectedRole, initialValues: false })
  }
  return (<>
    <div className="role-module p-3">
      <AddUpdateRole
        clearInitialValues={clearInitialValues}
        api={api}
        initialValues={selectedRole.initialValues ? selectedRole : {
          id: 0,
          name: ''
        }}
      />
      <div className="role-tabs">
        <Nav tabs>
          {api.data?.map((role:any) => {
            return <NavItem>
              <NavLink
                className={selectedRole.id === role.id ? "active" : ""}
                onClick={() => setSelectedRole(role)}
              >
                {role.name}
              </NavLink>
              {selectedRole.id === role.id && <Button
                color="link"
                className="m-0 p-0"
                onClick={() => setSelectedRole({ ...selectedRole, initialValues: true })}
              >
                {editSolid.icon}
              </Button>}
            </NavItem>
          })}
        </Nav>
        {selectedRole && <RolePermissions
          permissions={selectedRole.rolePermissions}
          api={api}
        />}

      </div>
    </div>

  </>)
}


export { RoleList }