import React, { useState, useContext, ReactNode } from "react";

interface LoadingContextType {
  dataIsLoading: boolean;
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = React.createContext<LoadingContextType | undefined>(
  undefined
);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dataIsLoading, setDataIsLoading] = useState(false);
  return (
    <>
      <LoadingContext.Provider value={{ dataIsLoading, setDataIsLoading }} >
        {children}
      </LoadingContext.Provider>
    </>
  );
};

export const useLoaderHelper = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoaderHelper must be used within a LoadingProvider");
  }
  return context;
};
