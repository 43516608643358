import { Button, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { downloadIcon } from "../../constant/icons";
import { useState } from "react";

const DownloadControl = (props: any) => {
    const { column, rowData } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    return (
        <>
            <Button color="link"
                disabled={rowData.jobStatus == "ERROR" || rowData.jobStatus == "DONE_WITH_ERROR" ? false : true}
                onClick={() => {
                    column.callBack({ ...rowData, clickType: "download" })
                }} className="text-success" id="downloadTooltip">{downloadIcon.icon}</Button>
            <UncontrolledTooltip isOpen={tooltipOpen} target="downloadTooltip" toggle={toggleTooltip}>
                download
            </UncontrolledTooltip>
        </>
    )
};

export { DownloadControl };