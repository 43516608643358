import { FormGroup } from "reactstrap"
import { file } from "../../../../../constant/icons"
import { FileUpload } from "../../../type"
import ReactSelect from "react-select"
import { useApp } from "../../../../../components/app"
import { useMemo } from "react"

const FunctionMap = (props:any) => {
    const {fileUpload,onFileUpdate,refresh,app}=props
    
    const onValueChange=(value:any,isSheet:boolean,fileName:string)=>{
        const selectedFile:FileUpload|null|undefined=fileUpload.find((x:FileUpload)=>x.file.name===fileName)
        if(selectedFile)
        {
            const selectedSheet=selectedFile.selectedSheet?.[0]
            selectedFile.selectedSheet=[{
                name: isSheet?value:selectedSheet?.name,
                columnMap: app.getSelectedFunctionColumnsItem(isSheet?selectedSheet?.functionCode:value),
                functionCode:isSheet?selectedSheet?.functionCode:value
              }]
              onFileUpdate(fileUpload)
        }
    }
    const functionMaps=useMemo(()=>{
      return <div className="attachment-data-list mb-3">
      {fileUpload.map((fileItem:FileUpload) => {
        return <div className={`attachment-data ${fileItem?.selectedSheet === undefined||fileItem?.selectedSheet[0]?.name === undefined||fileItem?.selectedSheet[0]?.functionCode === undefined?'not-completed-data':'completed-data'}`}>
          <a title="File" className="file-box mb-2">
            {file.icon}
            <p>{fileItem.file.name}</p>
          </a>
          <>
            <FormGroup className="form-floating">
              <ReactSelect
              placeholder="select a sheet"
              isDisabled={fileItem.totalSheet&&fileItem.totalSheet.length===1}
                value={fileItem.totalSheet&&fileItem.totalSheet.length===1?{
                  value: fileItem.totalSheet[0].name!,
                    label: fileItem.totalSheet[0].name!
                }:fileItem?.selectedSheet === undefined ? null : fileItem?.selectedSheet[0]?.name === undefined ? null : { value: fileItem?.selectedSheet[0]?.name, label: fileItem?.selectedSheet[0]?.name }}
                onChange={(e) => {onValueChange(e?.value,true,fileItem.file.name)}}
                options={fileItem.totalSheet ? fileItem.totalSheet?.map((item) => {
                  return {
                    value: item.name!,
                    label: item.name!
                  }
                }) : undefined} />
            </FormGroup>
            <FormGroup className="form-floating">
              <ReactSelect
                placeholder="select a function"
                value={app.getSelectedFunctionItem(fileItem?.selectedSheet === undefined ? undefined : fileItem?.selectedSheet[0]?.functionCode)}
                onChange={(e) => {onValueChange(e?.value,false,fileItem.file.name)}}
                options={app.functionDropDownList()} />
            </FormGroup>
          </>
        </div>
      })}
    </div> 
    },[refresh])
    return functionMaps
}
export {FunctionMap}