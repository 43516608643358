import { Button, FormGroup, Input } from "reactstrap";
import { confirmBox } from "../toast";

import { Link } from "react-router-dom";
import { connectIcon, connectToQboIcon, connectToXeroIcon, disconnectToQboIcon, disconnectToXeroIcon } from "../../constant/icons";
import { useAuth } from "../auth";
import { GetAppId } from "../app";
require('./switchButton.css')


const SwitchButton = (props: any) => {
    const loginType = GetAppId()
    const { disabled, column, rowData, value, message } = props;
    const isConnected = rowData.tenant?.[0]?.isConnected;

    const handleClick = async () => {
        const shouldProceed = isConnected ? await confirmBox(message) : true;
        if (shouldProceed && column.callBack) {
            column.callBack(rowData);
        }
    };
    return (<>
        {
            loginType == "xero" ?
                <>
                    {
                        <Link to={{}} title={isConnected ? "Disconnect from XERO" : "Connect to XERO"} onClick={handleClick}>
                            {isConnected ? disconnectToXeroIcon.icon : connectToXeroIcon.icon}
                        </Link>
                    }
                </> : loginType == "QBO" ?<>
                        <Link to={{}} title={isConnected ? "Disconnect from QuickBooks" : "Connect to QuickBooks"} onClick={handleClick}>
                            {isConnected ? disconnectToQboIcon.icon : connectToQboIcon.icon}
                        </Link>
                </>:
                <Button color="success" className={rowData.tenant?.[0]?.isConnected ? "disconnect_btn" : "connect_btn"} onClick={
                    async () => {
                        const response = rowData.tenant?.[0]?.isConnected ? await confirmBox(message) : !rowData.tenant?.[0]?.isConnected;
                        if (response && column.callBack) {
                            column.callBack(rowData)
                        }
                    }
                } >
                    {connectIcon.icon}<span>{rowData.tenant?.[0]?.isConnected ? "Disconnect" : "connect"}</span>
                </Button>

        }
    </>)
};

export { SwitchButton };