import { Nav } from "reactstrap"
import { AppLogo } from "../../constant/logo"
import { Link } from 'react-router-dom';
import { GetMenuList } from "../../constant/menus";

export const Aside = () => {
    const MenuList = GetMenuList()
    return <aside className="sidenav collapse">
        <div className="sidenav-logo">
            <Link to={'/'}>
                {AppLogo.main}
                {AppLogo.small}
            </Link>
        </div>
        <Nav vertical className='nav-main'>
            {MenuList}
        </Nav>
    </aside>
}