import {
  Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row
} from "reactstrap"
import plans_bg_color from '../../src/assets/images/hero_bg.png'
import plans_bg_noice from '../../src/assets/images/hero_Noise.png'

import convert_img1 from '../assets/images/mmc_convertor-img1.png'
import convert_img2 from '../assets/images/mmc_convertor-img2.png'
import convert_img3 from '../assets/images/mmc_convertor-img3.png'

import mmcfeature_mockup from '../assets/images/mmc_feature-mockup.png'

import booksintigration from '../assets/images/books_integration.png'
import zohobooks from '../assets/images/zoho-books.png'
import planspricing from '../assets/images/plans&pricing.png'

import { useState } from "react"
import { Header } from "./layout/userheader"
import { useLocation, useNavigate } from "react-router-dom"

const LandingPage = () => {
  const currentLocation = useLocation()
  let loginType: string | undefined | null = currentLocation.pathname.split('/')[1]
  if (!['odoo', "QBO", "xero", "zoho", "qbo", "XERO"].includes(loginType)) {
    loginType = undefined
  }
  console.log("loginType==", loginType)
  if (loginType === "qbo") {
    loginType = "QBO"
  }
  const [open, setOpen] = useState<any>('');
  const navigate = useNavigate()
  const toggle = (id: any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Header />
      <section className="plans_hero books-intergration">
        <Container className="h-100">
          <Row className="h-100 gx-5">
            <Col lg={12} sm={12} className="plans_main_text">
              <h1>ZOHO BOOKS<span> Integration</span></h1>              
            </Col>
            <Col lg={6} sm={12} className="books_integration_img">
              <img className="img-fluid" alt="background" src={booksintigration} />
            </Col>
            <Col lg={6} sm={12} className="plans_about_text">
              <h3>OUR CONVERSIONS ARE MADE OF IT!</h3>
              <p>Easily transfer your Excel data to Zoho Books. Follow our straight forward guide to enhance data accuracy, gain real-time insights, and simplify your workflow. Start your transition now!</p>
              <Button rounded color="primary" onClick={() => navigate(loginType ? `/${loginType}/login` : '/login')}>Try Excel to ZOHO BOOKS</Button>
            </Col>

          </Row>
        </Container>
        <img className='plans_bg_noice' alt="background" src={plans_bg_noice} />
        <img className='plans_bg_color' alt="background" src={plans_bg_color} />

      </section>
      <section className="plans_about about_mmc zoho-books">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={12}>
              <h3 className="history_title clip_title">What is <span>ZOHO BOOKS ?</span></h3>
            </Col>
            <Col lg={6} sm={12} className="plans_about_text">

              <p>Zoho Books is a comprehensive online accounting software designed to manage your finances, automate business workflows, and help you collaborate seamlessly across departments. It is a part of the Zoho suite of applications, known for its affordability, user-friendliness, and wide range of features that cater to the needs of small to medium-sized businesses.</p>
            </Col>
            <Col lg={6} sm={12} className="plans_about_img">
              <img className="img-fluid" alt="background" src={zohobooks} />
            </Col>


          </Row>

        </Container>
      </section>
      <section className="pricing_cards plan-price">
        <Container className="h-100">
          <Row> 
          <Col lg={12}>
              <h3 className="history_title clip_title">Plans and <span>Pricing</span></h3>
              <p>Flexible Plans & Competitive Pricing</p>
          </Col>

          </Row>

            <Row className="d-flex justify-content-center">
                <Col lg={10} sm={12}>
                    <div className="price_card"> 
                        <Card>
                          <CardBody>
                            <CardTitle tag="h5">
                            Free
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2"
                              tag="h6"
                            >
                              Get a taste of what Excel to ZOHO BOOKS can do for you.

                            </CardSubtitle>
                            <div>
                                <h2>$0<span>/ month</span></h2>
                            </div>
                            <Button className="btn-sm" onClick={() => navigate(loginType ? `/${loginType}/login` : '/login')}>
                              Get Started
                            </Button>
                            <ul>
                                <li>                                    
                                    <p><b>1</b> Organisation Limit</p>
                                </li>
                                <li>                                    
                                    <p><b>1 </b> User Limit</p>
                                </li>
                                <li>                                    
                                    <p>Email Support</p>
                                </li>
                                <li>                                    
                                    <p> No credit card requirement</p>
                                </li>
                                <li>                                   
                                    <p> 3k per csv transactional line limit</p>
                                </li>
                            </ul>
                          </CardBody>
                        </Card>
                        <Card className="recommended_plan">
                          <CardBody>
                            <CardTitle tag="h5">
                            Our new pricing plans are launching soon.
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2"
                              tag="h6"
                            >
                              
                            </CardSubtitle>

                            <div className="plans_about_img">
                                <img className="img-fluid" alt="background" src={planspricing} />
                            </div>
                            <ul>
                                <li>
                                  <p>Stay tuned for details and updates.</p>
                                </li>


                            </ul>
                            
                          </CardBody>
                        </Card>

                    </div>
                </Col>
            </Row>
        </Container>
      </section>      
      
      <section className="plans_about about_mmc_convertor">
        <Container>
          <Row>
          <Col lg={12} className="head-title">
              <h3 className="history_title clip_title">MMC Excel Convertor for <span>Zoho Books</span></h3>
              <p>What Can Offer You</p>
          </Col>
            <Col lg={4} sm={12}>
              <Card>              
                <CardBody>
                <img className="img-fluid" alt="mmc_convertor" src={convert_img1} />
                  <CardTitle tag="h5">
                  Effortless Conversion
                  </CardTitle>
                  <CardText>
                  Effortlessly convert Excel data to Zoho Books. Experience fast, high-volume transfers with minimal effort.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} sm={12}>
              <Card>              
                  <CardBody>
                  <img className="img-fluid" alt="mmc_convertor" src={convert_img2} />
                    <CardTitle tag="h5">
                    Multi-Currency Support
                    </CardTitle>
                    <CardText>
                    Maintains the same foreign currency transactions and exchange rates. 
                    </CardText>
                  </CardBody>
                </Card></Col>
            <Col lg={4} sm={12}>
              <Card>              
                  <CardBody>
                  <img className="img-fluid" alt="mmc_convertor" src={convert_img3} />
                    <CardTitle tag="h5">
                    Data Integrity & Security
                    </CardTitle>
                    <CardText>
                    We prioritize data integrity, ensuring no loss or corruption occurs during the transfer.
                    </CardText>
                  </CardBody>
                </Card></Col>
          </Row>
        </Container>
      </section>

      <section className="pricing_cards mmc_features">
        <Container>
          <Row>
            <Col lg={12}>
              <h3 className="history_title clip_title">What makes us <span>Special ?</span></h3>
            </Col>

            <Col lg={6} sm={12} className="mmc_features-img">
              <img className="img-fluid" alt="mmc feature mockup" src={mmcfeature_mockup} />
            </Col>
            <Col lg={6} sm={12} className="mmc_features_text">
               <h4>Easy to use :</h4>
               <p>Convert Excel data to Zoho Books easily with our user-friendly guide. Enjoy smooth, accurate transfers.</p>
               <h4>Efficient Data Transfer :</h4>
               <p>Efficiently transfer your Excel data to Zoho Books with fast, accurate, and hassle-free results.</p>
               <h4>Extensive volume :</h4>
               <p>Effortlessly convert extensive Excel data to Zoho Books with accurate, efficient transfers for large volumes.</p>
            </Col>            


          </Row>

        </Container>
      </section>           

      <section className="plans_about convert_action">
        <Container>
          <Row>
            <Col lg={12}>
              <h3 className="history_title">Just ask and we deliver.</h3>
              <h6>Data conversion can be customized depending on your requirement</h6>
            </Col>
            <Col lg={12} className="btn-box">
              <Button onClick={() => navigate(loginType ? `/${loginType}/login` : '/login')} rounded color="primary">Convert Now</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default LandingPage