import useSWR from "swr";
import { del, fetcher, get, post, statusUpdate, update } from "./api";
import { useState } from "react";
import { serverRequest } from "./types";
import { useAuth } from "../components/auth";
import { useLoaderHelper } from "./loaderHelper";
export interface useApiType {
  data: any;
  isLoading: boolean;
  error: any;
  rowCount: any;
  create: (value: any) => Promise<any>;
  updateValue: (value: any, id: String | number) => void;
  removeValue: (id: String | number) => void;
  status: (value: any) => void;
  GetValue: (url: string, body?: object) => any;
  serverRequest: (props: serverRequest) => any;
}
interface paginationType {
  skip: number;
  take: Number;
  limit?: Number;
  pageNo?: Number;
}
interface useApIType {
  route: string;
  IsPagination?: boolean;
  body?: any;
  dataLoad?: any;
  isDataLoading?: boolean;
}

export const useApi = (
  route: string,
  IsPagination: boolean = false,
  body: any = {},
  dataLoad: any = {},
  isDataLoading: boolean = false,
  addLoading:boolean=true
) => {
  const auth = useAuth();
  const routeLink = `/${route}`;
  const [pagination, setPagination] = useState<paginationType>({
    skip: 0,
    take: 10,
    limit: 10,
    pageNo: 0,
  });
  let bodyOfRequest = { ...body };

  if (IsPagination) {
    bodyOfRequest = { ...bodyOfRequest, ...pagination };
  }
  const { data, error, isLoading, isValidating, mutate } = useSWR<any[], Error>(
    auth.user && !isDataLoading ? [routeLink, bodyOfRequest] : null,
    () => fetcher(routeLink, bodyOfRequest,addLoading?loader:undefined),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      ...dataLoad,
    }
  );
  let dataList: any = [];
  if (IsPagination && data && data.length > 0) {
    dataList = data[0].data;
  } else {
    dataList = data;
  }
  const dataRefresh = (result: any) => {
    if (result?.status) {
      mutate();
    }
    return result;
  };
  const updatePagination = () => {
    function updatePageLimit(pageLimit: number = 10) {
      setPagination({
        ...pagination,
        skip:
          pageLimit *
          (pagination.pageNo === undefined ? 0 : (pagination.pageNo as number)),
        take: pageLimit,
        limit: pageLimit,
      });
    }
    function updateCurrentPageNo(currentPageNo: number = 0) {
      setPagination({
        ...pagination,
        skip:
          (pagination.limit === undefined ? 0 : (pagination.limit as number)) *
          currentPageNo,
        take: pagination.limit === undefined ? 0 : (pagination.limit as number),
        pageNo: currentPageNo,
      });
    }
    function getCurrentPageNo(): number {
      return pagination.pageNo === undefined
        ? 0
        : (pagination.pageNo as number);
    }
    function getTotalPage(): number {
      if (data && data.length > 0) {
        let totalPage = Math.ceil(
          data[0]?.total /
            (pagination.limit === undefined ? 0 : (pagination.limit as number))
        );
        if (totalPage < 1) totalPage = 1;
        return totalPage;
      } else {
        return 1;
      }
    }
    return {
      updatePageLimit,
      updateCurrentPageNo,
      getCurrentPageNo,
      getTotalPage,
    };
  };
  const loader = useLoaderHelper();

  const status = async (values: any) => {
    return await statusUpdate(routeLink, values, loader).then(dataRefresh);
  };
  const create = async (values: any): Promise<any> => {
    return await post(routeLink, values, undefined, loader).then(dataRefresh);
  };
  const updateValue = async (value: any) => {
    return await update(routeLink, value, loader).then(dataRefresh);
  };
  const removeValue = async (id: String | number) => {
    return await del(`${routeLink}/${id}`, loader).then(dataRefresh);
  };
  const GetValue = (url: string, body: object = {}) => {
    const { data, error, isLoading } = useSWR<any[], Error>(
      [url, body],
      () => fetcher(url, body, loader),
      {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
      }
    );
    return { data, error, isLoading };
  };
  const serverRequestDefaultValues = (props: serverRequest): serverRequest => {
    return {
      url: props.url,
      body: props.body || {},
      isMutate: props.isMutate || true,
      requestType: props.requestType || "Get",
      showResponseMessage: props.showResponseMessage || true,
    };
  };
  const serverRequest = async (props: serverRequest) => {
    const { url, requestType, body, isMutate, showResponseMessage } =
      serverRequestDefaultValues(props);
    let result = {};
    switch (requestType) {
      case "POST": {
        result = await post(url, body, undefined, loader);
        break;
      }
      case "PUT": {
        result = await update(url, body, loader);
        break;
      }
      default: {
        result = await get(url, body, loader);
        break;
      }
    }
    if (showResponseMessage) {
    }
    if (isMutate) {
      mutate();
    }
  };

  return {
    data: dataList,
    isLoading,
    error,
    rowCount:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.length > 0
        ? data[0]?.total
        : dataList.count,
    currentPage:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.length > 0
        ? data[0]?.pageNo
        : 0,
    limit:
      dataList === undefined || data === undefined
        ? 0
        : IsPagination && data.length > 0
        ? data[0]?.limit
        : 0,
    create,
    updateValue,
    removeValue,
    status,
    GetValue,
    mutate,
    updatePagination,
    serverRequest,
  };
};
