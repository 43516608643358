import {
  Badge,
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  clock,
  cloud,
  danger,
  date,
  edit,
  longBoldArrowRight,
  longarrowLeft,
  longarrowRight,
  right,
  errorIcon,
  errorlogIcon,
  progressIcon,
} from "../../constant/icons";
import { useApi } from "../../helper/apiHook";
import { useEffect, useState } from "react";
import { JobStatus } from "./type";

import { JobErrorView } from "./jobErrorView";
import { comingSoon } from "../../components/toast";

const JobDetails = (props: any) => {
  const { isJobDetailsOpen, jobDetailsToggle, openJobId, refreshData } = props;
  const api = useApi("jobDetails", false, { jobId: openJobId.id });
  const [openErrorLog, setOpenErrorLog] = useState(null);

  useEffect(() => {
    refreshData();
  }, []);

  const toggleErrorLog = () => {
    refreshData();
    api.mutate();
    setOpenErrorLog(null);
  };
  const getClass = (theRequest: string) => {
    switch (theRequest) {
      case JobStatus.DONE.id: {
        return "";
      }
      case JobStatus.DONE_WITH_ERROR.id: {
        return "has-error";
      }
      case JobStatus.ERROR.id: {
        return "has-error";
      }
      case JobStatus.IN_PROGRESS.id: {
        return "has-progress";
      }
      case JobStatus.IN_PROGRESS_RE_POST.id: {
        return "has-progress";
      }
      case JobStatus.PENDING.id: {
        return "";
      }
      case JobStatus.READY_TO_PROCESS.id: {
        return "";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <>
      {openErrorLog && (
        <JobErrorView
          jobPayloadId={openErrorLog}
          toggleErrorLog={toggleErrorLog}
        />
      )}

      <Modal
        className="side-modal job-modal job_log_modal"
        isOpen={isJobDetailsOpen}
        size="lg"
        centered
        scrollable={true}
        toggle={jobDetailsToggle}
      >
        <ModalHeader toggle={jobDetailsToggle}>
          <div className="job-head">
            <p>{openJobId.jobName}</p>
            <div className="job-head-detail">
              <Badge color="light" pill title="">
                {openJobId.createdUser.userName}
              </Badge>
              <Badge color="light" pill>
                {clock.icon} {openJobId.createdDate}
              </Badge>
              <Badge color="primary" pill>
                {cloud.icon} {openJobId.jobStatusLabel}
              </Badge>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <ListGroup className="error-log">
            {api.data?.map((item: any) => {
              return (
                <ListGroupItem className={getClass(item.status)}>
                  <ListGroupItemHeading>
                    <span>
                      {danger.icon}
                      {right.icon}
                      {progressIcon.icon}
                    </span>
                    <p>
                      {item.functionType.functionName}
                      <span>
                        <small>
                          {date.icon} {openJobId.createdDate}
                        </small>
                        <small>
                          <b>Total:</b>
                          {item.totalRecord}
                        </small>
                        <small>
                          <b className="text-success">Successfully:</b>{" "}
                          {item.totalRecord - item.errorCount},{" "}
                          <b className="text-danger">Error:</b>{" "}
                          {item.errorCount}
                        </small>
                      </span>
                    </p>
                  </ListGroupItemHeading>

                  {(item.errorMessage ||
                    (item.jobErrorLog && Array.isArray(item.jobErrorLog))) && (
                      <ListGroupItemText>
                        <div>
                          <ul>
                            {item.errorMessage &&
                              item.errorMessage.length > 0 && (
                                <li>
                                  {errorIcon.icon}

                                  <span><tr>{item.errorMessage}</tr>
                                    <tr>{item.errorDtl}</tr></span>


                                </li>
                              )}
                            {item.jobErrorLog &&
                              Array.isArray(item.jobErrorLog) &&
                              (item.jobErrorLog as []).length > 0 && (
                                <>
                                  {item?.jobErrorLog?.map((item: any) => {
                                    return (
                                      <li>
                                        {errorIcon.icon}
                                        <span><tr>{item.errorMessage}</tr>
                                          <tr>{item.errorDtl}</tr></span>
                                      </li>
                                    );
                                  })}
                                  <div>
                                    <Button
                                      color="link"
                                      onClick={() => {
                                        setOpenErrorLog(item.id);
                                      }}
                                    >
                                      {errorlogIcon.icon} Error log
                                    </Button>
                                  </div>
                                </>
                              )}
                          </ul>
                        </div>
                      </ListGroupItemText>
                    )}
                </ListGroupItem>
              );
            })}

            {/* End - lopp */}
          </ListGroup>
          {/* End Error log */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={jobDetailsToggle}
            outline
            size="sm"
          >
            {longarrowLeft.icon}back
          </Button>
          <span className="me-3">Total: {api.data?.length}</span>
        </ModalFooter>
      </Modal>
    </>
  );
};
export { JobDetails };
