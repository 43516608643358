import * as Yup from 'yup';

import { LoginFrom } from "./type";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const loginValidationSchema = Yup.object().shape<Record<keyof LoginFrom, Yup.AnySchema>>({
    userName: Yup.string().trim().required(),
    password: Yup
        .string()
        .matches(passwordRules, { message: "Invalid username or Password" })
        .required("Required"),
    loginType: Yup.string(),
})

const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email().required()
})
const resetPasswordValidationSchema = Yup.object().shape({
    password: Yup
        .string()
        .matches(passwordRules, { message: "Invalid Password" })
        .required("Required"),
    confirmPassword: Yup
        .string()
        .matches(passwordRules, { message: "Invalid  Password" })
        .required("Required").oneOf([Yup.ref('password')], 'Passwords must match')
})

const signUpValidationSchema = Yup.object().shape({
    password: Yup
        .string()
        .matches(passwordRules, { message: "Invalid Password" })
        .required("password is required"),
    name: Yup.string().required("Name is required"),
    appCode: Yup.string().required("Login App is required"),
    emailId: Yup.string().email("Invalid email format").required("Email is required"),
    mobileNo: Yup.string().matches(/^\d{10}$/, "Mobile number must be 10 digits").required("Mobile number is required"),
})

export { loginValidationSchema, forgotPasswordValidationSchema, resetPasswordValidationSchema, signUpValidationSchema }