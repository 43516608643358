import { useState } from "react"
import { WizardStep } from "./wizardStep"
import { FileUpload, wizardStepActionType, wizardStepType } from "../../type"
import { Footer } from "./footer"
import { Container } from "./container"
import { generateUniqueId, getSheetNames } from "./utility"
import { message } from "../../../../components/toast"
import { INVALID_SHEET_NAME, SELECT_A_FILE, VALID_DESTINATION } from "../../message"

const WizardBox = (props: any) => {
    const { api, closeAddUpdate,isUpdate } = props
    const [currentStep, setCurrentStep] = useState<wizardStepType>(wizardStepType.BrowserFile)
    const [fileUpload, setFileUpload] = useState<FileUpload[]>([])
    const [FileUploadRefresh, setFileUploadRefresh] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [resetId, setResetId] = useState<string | null>(null)
    const onFileUpdateRefresh = (status: boolean) => {
        setFileUploadRefresh(status)
    }
    const onFileUpdate = (updatedFiles: FileUpload[]) => {
        setFileUpload(updatedFiles)
        setRefresh(!refresh)
    }
    const onStepUpdate = async (actionType: wizardStepActionType) => {

        switch (actionType) {
            case wizardStepActionType.next: {
                if (currentStep < wizardStepType.ReviewAndUpload) {
                    switch (currentStep) {
                        case wizardStepType.BrowserFile: {
                            if (fileUpload.length > 0) {
                                setFileUpload(await getSheetNames({ fileUpload }))
                                onFileUpdateRefresh(false)
                                setResetId(null)
                                break;
                            }
                            else {
                                message('error', SELECT_A_FILE)
                                return;
                            }
                        }
                        case wizardStepType.FunctionMap: {
                            if (fileUpload.length > 0) {
                               let invalidMap = fileUpload.filter((x) =>{
                                if(!x.selectedSheet||x.selectedSheet.length<=0){
                                    x.selectedSheet=[{
                                        name:""
                                    }]
                                }
                                console.log("x.selectedSheet",x.selectedSheet)
                                if(!x.selectedSheet![0]?.name&&x.totalSheet&&x.totalSheet.length===1)
                                {
                                    x.selectedSheet[0].name=x.totalSheet[0].name
                                }
                                return !x.selectedSheet![0]?.name || !x.selectedSheet![0]?.functionCode
                               })
                                if (invalidMap && invalidMap.length > 0) {
                                    message('error', INVALID_SHEET_NAME)
                                    return;
                                }
                                setResetId(null)
                                break;
                            }
                            else {
                                setResetId(null)
                                break;
                            }
                        }
                        case wizardStepType.ColumnsMap: {
                            if (fileUpload.length > 0) {
                                let invalidMap = fileUpload.filter((x) => x.selectedSheet![0]?.columnMap && x.selectedSheet![0]?.columnMap?.filter(y => y.isRequired && y.Source.toString().trim().length <= 0).length > 0)
                                if (invalidMap && invalidMap.length > 0) {
                                    
                                    message('error',`<table>
                                    ${VALID_DESTINATION}
                                    ${invalidMap.map(x =>`${x.file.name}<br>`).join('')}
                                </table>`, true)
                                    return;
                                }
                                break;
                            }
                            else {
                                break;
                            }
                        }
                    }
                    setCurrentStep(currentStep + 1)
                }
                break;
            }
            case wizardStepActionType.back: {
                if (currentStep > wizardStepType.BrowserFile) {
                    setCurrentStep(currentStep - 1)
                }
                
                break;
            }
        }
    }
    const onColumnValueReset = async () => {
        setResetId(generateUniqueId())
    }
    return (<div className="wizard-box">
        <WizardStep
            currentStep={currentStep}
        />

        <Container
            currentStep={currentStep}
            onFileUpdate={onFileUpdate}
            onFileUpdateRefresh={onFileUpdateRefresh}
            api={api}
            isUpdate={isUpdate}
            closeAddUpdate={closeAddUpdate}
            fileUpload={fileUpload}
            refresh={refresh}
            FileUploadRefresh={FileUploadRefresh}
            resetId={resetId}
        />
        <Footer
            currentStep={currentStep}
            onStepUpdate={onStepUpdate}
            onColumnValueReset={onColumnValueReset}
        />
    </div>)
}
export { WizardBox }