import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Card, CardBody, FormGroup, Label, Row } from "reactstrap";
import { plus } from "../../constant/icons";
import { RoleValidationSchema } from "./validationSchema";
import { useLoaderHelper } from "../../helper/loaderHelper";

const AddUpdateRole = (props: any) => {
  const { api, initialValues, clearInitialValues } = props;
  const loader = useLoaderHelper();
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={RoleValidationSchema}
      onSubmit={(values, actions) => {
        if (values.id > 0) {
          const { rolePermissions, initialValues, ...addUpdateValues } = values;
          api.updateValue(addUpdateValues, loader)
            .then((result: any) => {
              if (result?.status) {
                clearInitialValues();
              }
            });
        } else {
          api.create(values).then((result: any) => {
            if (result?.status) {
              actions.resetForm({ values: initialValues });
            }
          });
        }
      }}
    >
      <Card className="border-0">
        <CardBody className="p-2">
          <Row className="mx-0 pt-0">
            <Form className="d-flex align-items-baseline role-add-box p-0">
              <FormGroup className="col ps-0 form-floating mb-0">
                <Field
                  name="name"
                  placeholder="roleName"
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
                <Label>
                  Role
                  <span className="text-danger required">*</span>
                </Label>
              </FormGroup>
              <Button type="submit" color="primary" className="ms-2 col-auto">
                {plus.icon}
                {initialValues.id > 0 ? "Update role" : "Add role"}
              </Button>
            </Form>
          </Row>
        </CardBody>
      </Card>
    </Formik>
  );
};

export { AddUpdateRole };
