import * as React from "react";

import {
  centerLength,
  getPagesArrayBySize,
  getPagesForCenter,
} from "ka-table/Utils/PagingUtils";

import { IPagingProps } from "ka-table/props";
import PagingIndex from "ka-table/Components/PagingIndex/PagingIndex";
import defaultOptions from "ka-table/defaultOptions";
import { getElementCustomization } from "ka-table/Utils/ComponentUtils";
import { updatePageIndex } from "ka-table/actionCreators";
import { Button } from "reactstrap";
import {
  doubleleftArrow,
  doublerightArrow,
  leftArrow,
  rightArrow,
} from "../../constant/icons";

const PagingPages: React.FunctionComponent<IPagingProps> = (props) => {
  const { childComponents, dispatch, pagesCount, pageIndex = 0 } = props;
  const pages = getPagesArrayBySize(pagesCount);
  React.useEffect(() => {
    if (pageIndex !== 0 && pageIndex >= pages.length) {
      dispatch(updatePageIndex(0));
    }
  }, [dispatch, pageIndex, pages]);

  const isEndShown =
    pageIndex < pages.length - centerLength &&
    pages.length > centerLength + Math.ceil(centerLength / 2);
  const isStartShown =
    pageIndex >= centerLength &&
    pages.length > centerLength + Math.ceil(centerLength / 2);
  const centerPages = getPagesForCenter(
    pages,
    isStartShown,
    isEndShown,
    pageIndex
  );
  return (
    <>
      {isStartShown && (
        <>
          <li className="page-item">
            <Button
              className="page-link"
              onClick={() => dispatch(updatePageIndex(0))}
            >
              {doubleleftArrow.icon}
            </Button>
          </li>
          <li className="page-item">
            <Button
              className="page-link"
              onClick={() => dispatch(updatePageIndex(pageIndex - 1))}
            >
              {leftArrow.icon}
            </Button>
          </li>
        </>
      )}
      {
        <>
          {!isStartShown && (
            <>
              <li className="page-item">
                <Button
                  disabled={pageIndex <= 0}
                  className="page-link"
                  onClick={() => dispatch(updatePageIndex(0))}
                >
                  {doubleleftArrow.icon}
                </Button>
              </li>
              <li className="page-item">
                <Button
                  disabled={pageIndex <= 0}
                  className="page-link"
                  onClick={() => dispatch(updatePageIndex(pageIndex - 1))}
                >
                  {leftArrow.icon}
                </Button>
              </li>
            </>
          )}

          {centerPages.map((value, index) => {
            return (
              <PagingIndex
                {...props}
                pageIndex={value}
                isActive={pageIndex === value}
                key={value}
                text={value + 1}
              />
            );
          })}
          {!isEndShown && (
            <>
              <li className="page-item">
                <Button
                  className="page-link"
                  onClick={() => dispatch(updatePageIndex(pageIndex + 1))}
                  disabled={pageIndex >= pages.length - 1}
                >
                  {rightArrow.icon}
                </Button>
              </li>
              <li className="page-item">
                <Button
                  disabled={pageIndex >= pages.length - 1}
                  className="page-link"
                  onClick={() => dispatch(updatePageIndex(pages.length - 1))}
                >
                  {doublerightArrow.icon}
                </Button>
              </li>
            </>
          )}
        </>
      }
      {isEndShown && (
        <>
          <li className="page-item">
            <Button
              className="page-link"
              onClick={() => dispatch(updatePageIndex(pageIndex + 1))}
            >
              {rightArrow.icon}
            </Button>
          </li>
          <li className="page-item">
            <Button
              className="page-link"
              onClick={() => dispatch(updatePageIndex(pages.length - 1))}
            >
              {doublerightArrow.icon}
            </Button>
          </li>
        </>
      )}
    </>
  );
};

export default PagingPages;
