const emailTemplate = `
<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <title>Welcome to ExelToXero!</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet">
    <style type="text/css">
    </style>
</head>
<body style="background-color: #f6f6f6;margin: 0;padding: 0;font-family: 'Poppins', sans-serif;">
    <div class="container"
        style="background-color: #fff;margin: 50px auto;max-width: 600px;padding: 1rem;box-shadow: 0px 0px 10px #ddd;border-radius: 8px;color: #333;font-size: 16px;line-height: 1.5;overflow: hidden;">
        <div class="header"
            style="margin: -1rem -1rem 0;padding: 1.2rem 1rem 0.8rem 1rem;text-align: center;display: inline-block;width: 100%;  background-color: orange;">
            <a href="#"><img src="http://accountsync.hcshub.in/accountsync-api/appLogo/logo.png"
                    alt="ExelToXero" style="height: 50px;" /></a>
            <h1
                style="font-size: 1.4rem;line-height: normal;font-weight: 700;margin: 0;margin-top: 0.5rem;text-align: center;letter-spacing: 0.5px;color: #4F2D7F;">
                Welcome to ExelToXero</h1>
        </div>
        <span
            style="width: 100%;display: inline-block;height: 1px;background: #ccc;float: left;margin-bottom: 0.3rem;">&nbsp;</span>
        <p style="margin: 15px 0 5px;display: inline-block; width:100%"><b>Dear {{FULLNAME}}</b></p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Welcome to ExelToXero</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">We're thrilled to have you on board!</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Congratulations on joining the ExelToXero community.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Your journey with ExelToXero begins now.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Get ready to experience seamless data management.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">We've designed ExelToXero to simplify your tasks.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Your productivity is about to get a major boost!</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Thank you,</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">ExelToXero Support Team.</p><!-- ... (previous code) ... -->
    </div>
</body>
</html>
`;
const jobStartTemplate = `
<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <title>Welcome to ExelToXero!</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet">
    <style type="text/css">
    </style>
</head>
<body style="background-color: #f6f6f6;margin: 0;padding: 0;font-family: 'Poppins', sans-serif;">
    <div class="container"
        style="background-color: #fff;margin: 50px auto;max-width: 600px;padding: 1rem;box-shadow: 0px 0px 10px #ddd;border-radius: 8px;color: #333;font-size: 16px;line-height: 1.5;overflow: hidden;">
        <div class="header"
            style="margin: -1rem -1rem 0;padding: 1.2rem 1rem 0.8rem 1rem;text-align: center;display: inline-block;width: 100%;  background-color: orange;">
            <a href="#"><img src="http://accountsync.hcshub.in/accountsync-api/appLogo/logo.png"
                    alt="ExelToXero" style="height: 50px;" /></a>
            <h1
                style="font-size: 1.4rem;line-height: normal;font-weight: 700;margin: 0;margin-top: 0.5rem;text-align: center;letter-spacing: 0.5px;color: #4F2D7F;">
                Welcome to ExelToXero</h1>
        </div>
        <span
            style="width: 100%;display: inline-block;height: 1px;background: #ccc;float: left;margin-bottom: 0.3rem;">&nbsp;</span>
        <p style="margin: 15px 0 5px;display: inline-block; width:100%"><b>Dear {{FULLNAME}}</b></p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Congratulations on embarking on your journey with ExelToXero!</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">We are thrilled to welcome you to our community.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">As you start using ExelToXero, get ready to streamline your data management processes.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Our platform has been designed to simplify your tasks and boost your productivity.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">With ExelToXero, managing your data has never been more seamless and efficient.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Thank you for choosing us as your data management solution.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">If you have any questions or need assistance, our Support Team is here to help.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Wishing you a successful journey ahead!</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Best regards,</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">The ExelToXero Support Team</p><!-- ... (previous code) ... -->
    </div>
</body>
</html>
`;
const jobEndEmailTemplate = `
<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <title>Thank You from ExelToXero</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet">
    <style type="text/css">
    </style>
</head>
<body style="background-color: #f6f6f6;margin: 0;padding: 0;font-family: 'Poppins', sans-serif;">
    <div class="container"
        style="background-color: #fff;margin: 50px auto;max-width: 600px;padding: 1rem;box-shadow: 0px 0px 10px #ddd;border-radius: 8px;color: #333;font-size: 16px;line-height: 1.5;overflow: hidden;">
        <div class="header"
            style="margin: -1rem -1rem 0;padding: 1.2rem 1rem 0.8rem 1rem;text-align: center;display: inline-block;width: 100%;  background-color: orange;">
            <a href="#"><img src="http://accountsync.hcshub.in/accountsync-api/appLogo/logo.png"
                    alt="ExelToXero" style="height: 50px;" /></a>
            <h1
                style="font-size: 1.4rem;line-height: normal;font-weight: 700;margin: 0;margin-top: 0.5rem;text-align: center;color: #4F2D7F;">
                Thank You from ExelToXero</h1>
        </div>
        <span
            style="width: 100%;display: inline-block;height: 1px;background: #ccc;float: left;margin-bottom: 0.3rem;">&nbsp;</span>
        <p style="margin: 15px 0 5px;display: inline-block; width:100%"><b>Dear {{FULLNAME}}</b></p>
        <p style="margin: 10px 0;display: inline-block;width:100%">As you conclude your journey with ExelToXero, we want to express our heartfelt gratitude for choosing our platform.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">It's been our privilege to assist you in managing your data seamlessly and effectively.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Your commitment to using ExelToXero has driven us to continually improve and innovate.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">As you move forward, we hope the experience with us has been productive and valuable to your endeavors.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Please don't hesitate to reach out if you have any feedback or if there's anything we can do to further support you.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Once again, thank you for being a part of ExelToXero.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Wishing you success in all your future ventures.</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">Best regards,</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">The ExelToXero Team</p><!-- ... (previous code) ... -->
    </div>
</body>
</html>
`;
const ForgotPasswordTemplate = `<!DOCTYPE html>
<html>

<head>
    <meta charset="utf-8">
    <title>Welcome to ExelToXero!</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet">
    <style type="text/css">
    </style>
</head>

<body style="background-color: #f6f6f6;margin: 0;padding: 0;font-family: 'Poppins', sans-serif;">
    <div class="container"
        style="background-color: #fff;margin: 50px auto;max-width: 600px;padding: 1rem;box-shadow: 0px 0px 10px #ddd;border-radius: 8px;color: #333;font-size: 16px;line-height: 1.5;overflow: hidden;">
        <div class="header"
            style="margin: -1rem -1rem 0;padding: 1.2rem 1rem 0.8rem 1rem;text-align: center;display: inline-block;width: 100%;  background-color: orange;">
            <a href="#"><img src="http://accountsync.hcshub.in/accountsync-api/appLogo/logo.png"
                    alt="GTAXFAQ" style="height: 50px;" /></a>
            <h1
                style="font-size: 1.4rem;line-height: normal;font-weight: 700;margin: 0;margin-top: 0.5rem;text-align: center;letter-spacing: 0.5px;color: #4F2D7F;">Fogot Password</h1>
        </div>
        <span
            style="width: 100%;display: inline-block;height: 1px;background: #ccc;float: left;margin-bottom: 0.3rem;">&nbsp;</span>
        <p style="margin: 15px 0 5px;display: inline-block; width:100%"><b>Dear {{FULLNAME}}</b></p>
        <p style="margin: 10px 0;display: inline-block;width:100%"> Welcome to ExelToXero</p>
        <p style="margin: 10px 0;display: inline-block;">You can reset your Password by clicking the button below: </p>
        <p><a
                href={{LINK}} title="Fogot Password" class="btn-primary" onMouseOver="this.style.background='#00A7B5'"
                onMouseOut="this.style.background='#4F2D7F'"
                style="background-color: #4F2D7F;border: none;border-radius: 100px;color: #fff;margin: 0.8rem auto -0.2rem;display: block;width: fit-content;font-size: 1rem;line-height: normal;padding: 0.6rem 1.5rem;text-decoration: none;transition: background-color 0.3s ease; min-height:20px;;">Fogot Password</a></p>

        <p style="margin: 10px 0;display: inline-block;width:100%">Thank you,</p>
        <p style="margin: 10px 0;display: inline-block;width:100%">ExelToXero Support Team.</p>
    </div>
</body>

</html>`;

export const EmailContent = [
  {
    value: 1,
    subject: "Welcome email",
    Content: emailTemplate,
  },
  {
    value: 2,
    subject: "Forgate password email",
    Content: ForgotPasswordTemplate,
  },
  {
    value: 3,
    subject: "Function start email",
    Content: jobStartTemplate,
  },
  {
    value: 4,
    subject: "Send notification to first contact",
    Content: "",
  },
  {
    value: 5,
    subject: "Function end email",
    Content: jobEndEmailTemplate,
  },
];
export const EmailFields = [
  {
    id: 1,
    customName: "{{LINK}}",
  },
  {
    id: 2,
    customName: "{{ADMIN}}",
  },
  {
    id: 3,
    customName: "{{EMAIL}}",
  },
  {
    id: 4,
    customName: "{{PASSWORD}}",
  },
  {
    id: 5,
    customName: "{{FULLNAME}}",
  },
  {
    id: 6,
    customName: "{{NAME}}",
  },
  {
    id: 7,
    customName: "{{OTP}}",
  },
  {
    id: 8,
    customName: "{{REPORTTITLE}}",
  },
  {
    id: 9,
    customName: "{{IMAGEONE}}",
  },
  {
    id: 10,
    customName: "{{LOGO}}",
  },
  {
    id: 11,
    customName: "{{ROLENAME}}",
  },
];
export const config = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "ul",
    "ol",
    "|",
    "center",
    "left",
    "right",
    "justify",
    "|",
    "link",
  ],
  uploader: { insertImageAsBase64URI: true },
  removeButtons: ["brush", "file"],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
};

export const handleCopyClick = async (values: any) => {
  try {
    await navigator.clipboard.writeText(values);
  } catch (error) {
    console.log("Error copying text:", error);
  }
};

export const initialTemplateState = () => {
  return {
    id: "",
    isActive: "",
    subject: "",
    emailBody: "",
  };
};

// setSwitchStatus(
//   (await confirmBox(
//     !switchStatus
//       ? "Are you sure, Do you want to activate this tamplate?."
//       : "Are you sure, Do you want to deactivate this tamplate?."
//   ))
//     ? !switchStatus
//     : switchStatus
// );
// updateStatus({
//   id: initialTemplateValues?.id,
//   isActive: switchStatus,
// });
