import { Button, FormGroup, Label } from "reactstrap";
import { file } from "../../../../../constant/icons";
import { FileUpload, columnMap, sheet } from "../../../type";
import { post } from "../../../../../helper/api";
import {
  Job,
  columnMap as jobColumnMap,
  fileList,
  sheet as jobSheet,
} from "./type";
import { useState } from "react";
import { message } from "../../../../../components/toast";
import { Field, Form, Formik } from "formik";
import { JobValidationSchema } from "./validationSchema";
import { useLoaderHelper } from "../../../../../helper/loaderHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../components/auth";

const ReviewAndUpload = (props: any) => {
  const { fileUpload, app, api, closeAddUpdate, isUpdate } = props;
  const loader = useLoaderHelper();
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Formik
      initialValues={{
        name: "",
        jobStartWithSubmit: false,
      }}
      //validationSchema={JobValidationSchema}
      onSubmit={async (values) => {
        let formData = new FormData();
        const fileList: fileList[] = [];
        fileUpload.forEach((item: FileUpload) => {
          if (item.selectedSheet) {
            formData.append("files", item.file);
            fileList.push({
              name: item.file.name,
              sheetList: item.selectedSheet.map<jobSheet>((sheet: sheet) => {
                return {
                  name: sheet.name,
                  functionCode: sheet.functionCode,
                  columnMap: sheet.columnMap
                    ?.map<jobColumnMap>((map: columnMap) => {
                      return {
                        destinationCode: map.DestinationCode,
                        source: map.Source,
                      };
                    })
                    .filter((x) => x.source.toString().trim().length > 0),
                };
              }),
            });
          }
        });
        formData.append(
          "fileList",
          JSON.stringify({
            name: values.name,
            jobStartWithSubmit: values.jobStartWithSubmit,
            tenantId: app.conversation.tenant[0].id,
            isUpdate: isUpdate || false,
            fileList: fileList,
          })
        );
        let response = await post("/job", formData, undefined, loader);
        if (response && response.status) {
          try {
            console.log("conversation===", app.conversation)
            if (!app.conversation && location.pathname == "/modification") {
              navigate("/")
            }
            const path = location.pathname;
            const isModification: any = path.includes('/modification');
            if (isModification) {
              if (auth.user?.role == "User") {
                navigate(`/${auth.user?.loginType + "/job"}`, {
                  state: { action: 'save', data: app.conversation },
                });
              } else {
                navigate("/job", {
                  state: { action: 'save', data: app.conversation },
                });
              }
            }
            api.mutate();
            closeAddUpdate();
          } catch (error) {
            console.log("Errp", error);
          }
        }
      }}
    >
      {(formProps) => (
        <Form className="mx-0" onSubmit={formProps.handleSubmit}>
          <div className="row file-process-header">
            <FormGroup className="col-5 form-floating ps-0">
              <Field
                value={formProps.values.name}
                className="form-control"
                name="name"
                placeholder="Enter Function Name"
              />
              <Label>Function Name</Label>
            </FormGroup>
            <FormGroup className="col-3 d-flex align-items-center gap-2">
              <Label className="m-0 job_title_check">
                <Field type="checkbox" name="jobStartWithSubmit" />
                Function Start With Submit
              </Label>
            </FormGroup>
          </div>
          <div className="file-process-list mb-3">
            {fileUpload.map((item: FileUpload) => {
              return (
                <div className="file-process-data">
                  <div className="file-process-content">
                    <a
                      href="javascript:void(0);"
                      title="File"
                      className="file-box"
                    >
                      {file.icon}
                      <div>
                        <p>{item.file.name}</p>
                        <span>
                          <b>Function : </b>
                          {app.getSelectedFunctionItem(
                            item?.selectedSheet?.[0].functionCode
                          )?.label ?? ""}
                        </span>
                        <span>
                          <b>Sheet : </b>
                          {item?.selectedSheet?.[0].name}
                        </span>
                      </div>
                    </a>
                  </div>
                  {/* <Progress
          animated
          color="warning"
          value={75}
          style={{
            height: '8px'
          }}
        /> */}
                </div>
              );
            })}
          </div>
          <Button
            color="primary"
            disabled={!formProps.isValid || formProps.isSubmitting}
            className="review-submit"
            type="submit"
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export { ReviewAndUpload };
