import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { resetPasswordValidationSchema } from "./validationSchema";
import { post } from "../../helper/api";
import { useLoaderHelper } from "../../helper/loaderHelper";

export const ResetPassword = (props: any) => {
  console.log("The Comming Of Data");
  const { token } = useParams();
  const navigate = useNavigate();
  const loader = useLoaderHelper()

  if (!token) {
    navigate("/login");
  }

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={resetPasswordValidationSchema}
      onSubmit={async (values, actions) => {
        const result = await post(
          "/resetPassword",
          {
            token: token,
            password: values.password,
            confirmPassword: values.confirmPassword,
          },
          undefined,
          loader
        );
        if (result?.status) {
          navigate("/login");
        }
      }}
    >
      {(formProps) => (
        <Form className="form-horizontal form-login row">
          <FormGroup>
            <Label className="form-label" htmlFor="password"></Label>
            <Field
              className="form-control"
              id="password"
              name="password"
              placeholder="New password"
              type="password"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-danger"
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-label" htmlFor="password"></Label>
            <Field
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="text-danger"
            />
          </FormGroup>
          <Col lg="12">
            <Button color="primary" block className="w-100" type="submit">
              Submit
            </Button>
          </Col>
          <Col lg="12" className="text-center">
            <Link to="/login" className="pt-3 d-inline-block mx-auto">
              Sign in
            </Link>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
